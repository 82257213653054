import { Box, Card, CardContent, List, ListItemButton, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRoutine } from "../data/routine.api";
import { RootState } from "../../../store";
import secondsFormat from "../../../shared/secondsFormat";
import { createLog, getLogLatest } from "../../log/data/log.api";
import { CheckCircle } from "@mui/icons-material";
import moment from "moment";

export default function RoutineList() {

    const dispatch = useDispatch<any>()

    const { routineList } = useSelector((state: RootState) => state.routine)

    useEffect(() => {
        dispatch(getRoutine())
    }, [dispatch])

    const handlePlay = async (id: number) => {
        await dispatch(createLog({ routine_id: id })).unwrap()
        await dispatch(getLogLatest()).unwrap()
    }

    return (
        <Card sx={{ height: "auto" }}>
            <CardContent>
                <List>
                    {routineList.map(routine => {
                        const mandatoryMinutes = routine.minute_day_list[moment().day()]
                        const mandatorySeconds = mandatoryMinutes * 60
                        return (
                            <ListItemButton key={routine.id} onClick={() => handlePlay(routine.id)}>
                                <ListItemText primary={routine.name} />
                                <ListItemSecondaryAction>
                                    <Box display={"flex"} gap={1}>
                                        {secondsFormat(routine.total_second)} / {secondsFormat(mandatorySeconds)}
                                        {(routine.total_second >= mandatorySeconds) && (
                                            <CheckCircle />
                                        )}
                                    </Box>
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        )
                    })}
                </List>
            </CardContent>
        </Card>
    )
}