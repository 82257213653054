import { Container, Grid } from "@mui/material";
import LogWeek from "../features/log/components/LogWeek";
import ClientFinanceCount from "../features/client/components/ClientFinanceCount";
import LogTimeline from "../features/log/components/LogTimeline";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import LogStatisticHeader from "../features/log/components/LogStatisticHeader";
import LogRoutine from "../features/log/components/LogRoutine";

export default function StatisticPage() {


    const { showMoney } = useSelector((state: RootState) => state.containers)

    return (
        <Container maxWidth={"xl"}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <LogStatisticHeader />
                </Grid>
                <Grid item xs={12}>
                    <LogTimeline />
                </Grid>
                <Grid item xs={12}>
                    <LogWeek />
                </Grid>
                <Grid item xs={12}>
                    <LogRoutine />
                </Grid>
                {showMoney && (
                    <Grid item xs={12}>
                        <ClientFinanceCount />
                    </Grid>
                )}
            </Grid>
        </Container>
    )
}