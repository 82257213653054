import { createSlice } from '@reduxjs/toolkit'
import { createCash, deleteCash, editCash, getCash, getCashByTask } from './cash.api'
import { InterfaceCashData } from './cash.interface'

export interface CashState {
    loading: boolean,
    error: any,
    cashList: InterfaceCashData[],
    cashListByTask: InterfaceCashData[]
}

const initialState: CashState = {
    loading: false,
    error: undefined,
    cashList: [],
    cashListByTask: []
}

export const cashSlice = createSlice({
    name: 'cash',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getCash.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getCash.fulfilled, (state, action) => {
            state.loading = false
            state.cashList = action.payload.data.data
        })
        builder.addCase(getCash.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(getCashByTask.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getCashByTask.fulfilled, (state, action) => {
            state.loading = false
            state.cashListByTask = action.payload.data.data
        })
        builder.addCase(getCashByTask.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(createCash.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createCash.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createCash.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(editCash.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editCash.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editCash.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(deleteCash.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteCash.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteCash.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
    },
})

export default cashSlice.reducer