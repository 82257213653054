import { Box, Button, Card, CardContent, Checkbox, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import Color from "../../../shared/Color";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import moneyParser from "../../../shared/moneyParser";
import { InterfaceTaskData } from "../../task/data/task.interface";
import { getTaskDone } from "../../task/data/task.api";
import { createBill } from "../../bill/data/bill.api";

export function TaskDone() {

    const dispatch = useDispatch<any>()

    const { taskDone } = useSelector((state: RootState) => state.task)

    const [clientIdExpanded, setClientIdExpanded] = useState<number | null>(null)
    const [taskSelected, setTaskSelected] = useState<InterfaceTaskData[]>([])

    useEffect(() => {
        dispatch(getTaskDone())
    }, [dispatch])

    useEffect(() => {
        setTaskSelected([])
    }, [clientIdExpanded])

    const handleBill = () => {
        if (clientIdExpanded) {
            dispatch(createBill({
                client_id: clientIdExpanded,
                task_id_list: taskSelected.map(task => task.id)
            }))
                .unwrap()
                .then(() => {
                    dispatch(getTaskDone())
                })
        }
    }

    return (
        <Box>
            {taskDone.map((client) => {
                return (
                    <Card key={client.id} sx={{ marginBottom: 1 }}>
                        <CardContent>
                            <Box display={"flex"} justifyContent={"space-between"} marginBottom={1}>
                                <Box display={"flex"} gap={1}>
                                    <Color color={client.color} />
                                    <Typography variant="h6">{client.name}</Typography>
                                </Box>
                                <Box display={"flex"} alignItems={"center"}>
                                    <Typography variant="h6">{moneyParser(client.job_list.reduce((total, job) => total + job.task_list.reduce((t, task) => t + (task.price - task.cash_list.reduce((total, cash) => total + cash.value, 0)), 0), 0))}</Typography>
                                    {(client.id === clientIdExpanded)
                                        ? (
                                            <IconButton onClick={() => setClientIdExpanded(null)}>
                                                <KeyboardArrowUp />
                                            </IconButton>
                                        )
                                        : (
                                            <IconButton onClick={() => setClientIdExpanded(last => client.id)}>
                                                <KeyboardArrowDown />
                                            </IconButton>
                                        )
                                    }
                                </Box>
                            </Box>
                            {client.job_list.filter(job => job.client_id === clientIdExpanded).map((job) => {
                                const isJobTaskAll = job.task_list.every(task => taskSelected.map(t => t.id).includes(task.id))
                                return (
                                    <Box>
                                        <Card key={job.id} sx={{ marginBottom: 1 }}>
                                            <CardContent>
                                                <Box display={"flex"} justifyContent={"space-between"} marginBottom={1}>
                                                    <Box display={"flex"} alignItems={"center"} gap={1}>
                                                        <Checkbox checked={isJobTaskAll} onChange={() => isJobTaskAll ? setTaskSelected(last => last.filter(l => !job.task_list.map(t => t.id).includes(l.id))) : setTaskSelected(last => [...last, ...job.task_list])} />
                                                        <Color color={job.color} />
                                                        <Typography variant="h6">{job.name}</Typography>
                                                    </Box>
                                                    <Box display={"flex"} alignItems={"center"}>
                                                        <Typography variant="h6">{moneyParser(job.task_list.reduce((t, task) => t + (task.price - task.cash_list.reduce((total, cash) => total + cash.value, 0)), 0))}</Typography>
                                                    </Box>
                                                </Box>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell></TableCell>
                                                            <TableCell>Name</TableCell>
                                                            <TableCell>Price</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {job.task_list.map((task) => (
                                                            <TableRow>
                                                                <TableCell><Checkbox checked={taskSelected.map(task => task.id).includes(task.id)} onChange={(e) => e.target.checked ? setTaskSelected(last => [...last, task]) : setTaskSelected(last => last.filter(t => t.id !== task.id))} /></TableCell>
                                                                <TableCell>{task.name}</TableCell>
                                                                <TableCell>{moneyParser(task.price - task.cash_list.reduce((total, cash) => total + cash.value, 0))}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                )
                            })}
                            {(client.id === clientIdExpanded && taskSelected.length > 0) && <Button fullWidth sx={{ marginTop: 1 }} onClick={handleBill}>Bill</Button>}
                        </CardContent>
                    </Card>
                )
            })}
        </Box>
    )
}