export interface InterfaceTaskData {
    id: number,
    ts_created: string,
    job_id: number,
    name: string,
    price: number,
    description: string,
    date_start: string,
    date_due: string,
    is_done: boolean,
    is_pending: boolean,
    job: {
        name: true,
        client: {
            name: string
        }
    },
    cash_list: {
        id: number,
        ts_created: string,
        task_id: number,
        value: number,
        description: string,
    }[]
}

export interface InterfaceTaskDataTree {
    id: number,
    name: string,
    color: string,
    job_list: {
        id: number,
        name: string,
        color: string,
        client_id: number,
        task_list: InterfaceTaskData[]
    }[]
}

export interface InterfaceTaskRecent {
    id: number,
    name: string,
    job: {
        name: string,
        color: string,
        client: {
            name: string,
            color: string
        }
    },
}

export interface InterfaceTaskForm {
    id: number,
    ts_created: string,
    job_id: number,
    name: string,
    price: number,
    description: string,
    date_start: string,
    date_due: string | null,
    is_done: boolean
}

export interface InterfaceTaskCreate {
    job_id?: number,
    name: string,
    price: number,
    description: string,
    date_start: string,
    date_due: string | null,
}

export interface InterfaceTaskEdit {
    id: number,
    job_id?: number,
    name?: string,
    price?: number,
    description?: string,
    date_start?: string,
    date_due?: string | null,
    is_done?: boolean,
    is_pending?: boolean
}

export const InitialTaskForm: InterfaceTaskForm = {
    id: 0,
    ts_created: "",
    job_id: 0,
    name: "",
    price: 0,
    description: "",
    date_start: "",
    is_done: false,
    date_due: null
}