import { Box, Button, Container, Typography } from "@mui/material";
import JobTable from "../../features/job/components/JobTable";
import { Add } from "@mui/icons-material";
import CustomModal from "../../shared/CustomModal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import alertDelete from "../../shared/alert/alertDelete";
import { deleteJob, getJob } from "../../features/job/data/job.api";
import alertSuccess from "../../shared/alert/alertSuccess";
import JobForm from "../../features/job/components/JobForm";
import { InterfaceJobForm } from "../../features/job/data/job.interface";

export default function JobPage() {

    const dispatch = useDispatch<any>()

    const [modalAdd, setModalAdd] = useState<boolean>(false)
    const [dataSelected, setDataSelected] = useState<InterfaceJobForm | undefined>(undefined)

    const handleDelete = (id: number) => {
        alertDelete()
            .then(async isConfirmed => {
                if (isConfirmed) {
                    try {
                        await dispatch(deleteJob(id))
                        await alertSuccess("Berhasil menghapus data")
                        await dispatch(getJob())
                    } catch (error) { }
                }
            })
    }

    return (
        <Container>
            <CustomModal
                open={modalAdd}
                title="Add Job"
                onClose={() => setModalAdd(false)}
                component={<JobForm mode="ADD" onSubmit={() => setModalAdd(false)} />}
            />
            <CustomModal
                open={Boolean(dataSelected)}
                title="Edit Job"
                onClose={() => setDataSelected(undefined)}
                component={<JobForm mode="EDIT" data={dataSelected} onSubmit={() => setDataSelected(undefined)} />}
            />
            <Typography variant="h3">Job</Typography>
                <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button startIcon={<Add />} onClick={() => setModalAdd(true)}>Add</Button>
                </Box>
            <br />
            <JobTable onEdit={setDataSelected} onDelete={handleDelete} />
        </Container>
    )
}