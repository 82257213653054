export interface InterfaceRoutineData {
    id: number,
    ts_created: string,
    name: string,
    minute_day_list: number[],
    total_second: number
}

export interface InterfaceRoutineForm {
    id: number,
    name: string,
    minute_day_list: number[]
}

export interface InterfaceRoutineCreate {
    name: string,
    minute_day_list: number[]
}

export interface InterfaceRoutineEdit {
    id: number,
    name?: string,
    minute_day_list?: number[]
}

export const InitialRoutineForm: InterfaceRoutineForm = {
    id: 0,
    name: "",
    minute_day_list: [0, 0, 0, 0, 0, 0, 0]
}