export interface InterfaceCashData {
    id: number,
    ts_created: string,
    task_id: number,
    value: number,
    description: string,
    task: {
        name: string,
        job: {
            name: string,
            client: {
                name: string
            }
        }
    }
}

export interface InterfaceCashCreate {
    task_id: number,
    value: number,
    description: string
}

export interface InterfaceCashForm {
    id: number,
    task_id: number,
    value: number,
    description: string
}

export interface InterfaceCashEdit {
    id: number,
    task_id?: number,
    value?: number,
    description?: string
}

export const InitialCashForm: InterfaceCashForm = {
    id: 0,
    task_id: 0,
    value: 0,
    description: ""
}