import { Box, Button, Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect } from "react";
import { getTask } from "../data/task.api";
import CustomTable from "../../../shared/CustomTable";
import { InterfaceTaskData } from "../data/task.interface";
import { Delete, Edit } from "@mui/icons-material";
import moment from "moment";
import moneyParser from "../../../shared/moneyParser";

export default function TaskTable(props: {
    onEdit?: (data: InterfaceTaskData) => void,
    onDelete?: (id: number) => void
}) {

    const dispatch = useDispatch<any>()

    const { taskList } = useSelector((state: RootState) => state.task)

    useEffect(() => {
        dispatch(getTask())
    }, [dispatch])

    return (
        <Box>
            <CustomTable
                columns={[
                    {
                        name: "Client",
                        key: "job_id",
                        render: (data: InterfaceTaskData) => data.job.client.name
                    },
                    {
                        name: "Job",
                        key: "job_id",
                        render: (data: InterfaceTaskData) => data.job.name
                    },
                    {
                        name: "Nama",
                        key: "name"
                    },
                    {
                        name: "Description",
                        key: "description"
                    },
                    {
                        name: "Price",
                        key: "price",
                        render: (data: InterfaceTaskData) => moneyParser(data.price)
                    },
                    {
                        name: "Date Start",
                        key: "date_start",
                        render: (data: InterfaceTaskData) => moment(data.date_start).format("YYYY-MM-DD")
                    },
                    {
                        name: "Date Due",
                        key: "date_due",
                        render: (data: InterfaceTaskData) => data.date_due ? moment(data.date_due).format("YYYY-MM-DD") : '-'
                    },
                    {
                        name: "Done",
                        key: "is_done",
                        render: (data: InterfaceTaskData) => <Checkbox checked={data.is_done} />
                    }
                ]}
                dataList={taskList}
                actionList={(props.onEdit || props.onDelete)
                    ? (data: InterfaceTaskData) => (
                        <Box display={"flex"} justifyContent={"center"} gap={1}>
                            {props.onEdit && (<Button startIcon={<Edit />} color="warning" onClick={() => props.onEdit && props.onEdit(data)}>Edit</Button>)}
                            {props.onDelete && (<Button startIcon={<Delete />} color="error" onClick={() => props.onDelete && props.onDelete(data.id)}>Hapus</Button>)}
                        </Box>
                    )
                    : undefined
                }
            />
        </Box>
    )
}