import { TextField } from "@mui/material";
import moment from "moment";
import { ChangeEvent, ChangeEventHandler } from "react";

export default function FormDate(props: {
    label: string,
    name?: string,
    value?: string | null,
    required?: boolean,
    disabled?: boolean,
    onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}) {

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        props.onChange && props.onChange({
            ...e,
            target: {
                ...e.target,
                name: props.name || "",
                value: e.target.value ? moment(e.target.value).format('YYYY-MM-DD') : null as unknown as string
            }
        })
    }

    return (
        <TextField
            fullWidth
            type="date"
            label={props.label}
            name={props.name}
            value={moment(props.value || "").format('YYYY-MM-DD')}
            onChange={handleChange}
            required={props.required}
            disabled={props.disabled}
            InputLabelProps={{ shrink: true }}
        />
    )
}