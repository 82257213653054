export interface InterfaceLogin {
    username: string,
    password: string
}

export interface InterfaceLoginResponse {
    id: number,
    ts_created: string,
    key: string,
    username: string,
    password: string,
    role_id: number,
    role: {
        access: Array<{
            url: string,
            access: string[]
        }>
    }
}

export const InitialLoginResponse: InterfaceLoginResponse = {
    id: 0,
    ts_created: "",
    key: "",
    username: "",
    password: "",
    role_id: 0,
    role: {
        access: []
    }
}