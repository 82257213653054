import { Card, CardContent, CardHeader } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { LineChart } from "@mui/x-charts";
import { useEffect } from "react";
import { getRoutine } from "../../routine/data/routine.api";
import moment from "moment";
import dataProcessToDaily from "../../../shared/dataProcessToDaily";
import { InterfaceLogDataDetail } from "../data/log.interface";
import secondsFormat from "../../../shared/secondsFormat";

export default function LogRoutine() {

    const dispatch = useDispatch<AppDispatch>()

    const { logList, statisticDateStart, statisticDateEnd } = useSelector((state: RootState) => state.log)
    const { routineList } = useSelector((state: RootState) => state.routine)

    useEffect(() => {
        dispatch(getRoutine())
    }, [dispatch])

    const logListDaily: InterfaceLogDataDetail[][] = dataProcessToDaily(statisticDateStart, statisticDateEnd, logList)

    return (
        <Card>
            <CardContent>
                <CardHeader title="Routine" />
                <LineChart
                    height={300}
                    series={routineList.map((routine) => {
                        return {
                            label: routine.name,
                            data: logListDaily.map(logDaily => {
                                return logDaily.filter(log => log.routine?.id === routine.id).reduce((total, log) => total + log.total_second, 0)
                            }),
                            valueFormatter: (v) => secondsFormat(v || 0)
                        }
                    })}
                    xAxis={[{
                        scaleType: 'point',
                        data: logListDaily.map((_, i) => moment(statisticDateStart).add(i, "day").format("YYYY-MM-DD")),
                    }]}
                    yAxis={[{
                        valueFormatter: (v) => secondsFormat(v)
                    }]}
                    margin={{
                        left: 75
                    }}
                />
            </CardContent>
        </Card>
    )
}