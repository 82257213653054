import moment from "moment"
import { InterfaceLogDataDetail } from "../features/log/data/log.interface"

export default function dataProcessToDaily(dateStart: string, dateToday: string, logList: InterfaceLogDataDetail[]): InterfaceLogDataDetail[][] {
    const result: InterfaceLogDataDetail[][] = []
    const momentStart = moment(dateStart)
    const momentToday = moment(dateToday)
    for (let i = momentStart; i <= momentToday; i.add(1, "day")) {
        const thisDate = i.format("YYYY-MM-DD")
        const logListThisDate = logList.filter((log) => moment(log.ts_created).format("YYYY-MM-DD") === thisDate)
        if (logListThisDate.length === 0) {
            const logListNextDate = logList.filter((log) => moment(log.ts_created).diff(i, "seconds") < 0)
            result.push([
                {
                    ...logListNextDate[logListNextDate.length - 1],
                    total_second: 86400
                }
            ])
        }
        else {
            const logOneBeforeList = logList.filter(log => moment(log.ts_created).diff(moment(logListThisDate[0].ts_created)) < 0)
            if (logOneBeforeList.length > 0) {
                const logOneBefore = logOneBeforeList[logOneBeforeList.length - 1]
                const secondBefore = i.clone().add(-1, "day").endOf('day').diff(moment(logOneBefore.ts_created), 'seconds')
                logListThisDate.unshift({ ...logOneBefore, total_second: logOneBefore.total_second - secondBefore })
            }
            const lastLogListThisDate = logListThisDate[logListThisDate.length - 1]
            result.push([
                ...logListThisDate.filter((_, i) => i < logListThisDate.length - 1),
                {
                    ...lastLogListThisDate,
                    total_second: thisDate === momentToday.format("YYYY-MM-DD")
                        ? moment().diff(moment(logListThisDate[logListThisDate.length - 1].ts_created), 'seconds')
                        : moment(lastLogListThisDate.ts_created).endOf('day').diff(moment(logListThisDate[logListThisDate.length - 1].ts_created), 'seconds')
                }
            ])
        }
    }
    return result
}