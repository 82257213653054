import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClient } from "../data/client.api";
import { RootState } from "../../../store";
import { InterfaceClientData } from "../data/client.interface";

export default function ClientSelect(props: { value: number | undefined, onChange: (value: number | undefined, valueDetail: InterfaceClientData | undefined) => void }) {

    const dispatch = useDispatch<any>()

    const { loading, clientList } = useSelector((state: RootState) => state.client)

    useEffect(() => {
        dispatch(getClient())
    }, [dispatch])

    return (
        <Box>
            {loading
                ? (
                    <TextField fullWidth label="Client" />
                )
                : (
                    <Autocomplete
                        disablePortal
                        fullWidth
                        options={clientList}
                        getOptionLabel={options => options.name}
                        value={clientList.filter(client => client.id === props.value)[0]}
                        onChange={(_, value) => props.onChange(value?.id || undefined, value || undefined)}
                        renderInput={(params) => <TextField {...params} label="Client" required />}
                    />
                )
            }
        </Box>
    )
}