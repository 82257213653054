import "./assets/style.scss";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Box, CircularProgress, CssBaseline, Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from './store';
import Layout from './containers/components/Layout';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ReactNode, useEffect, useState } from 'react';
import routeList from './routeList';
import alertError from "./shared/alert/alertError";
import Login from "./features/auth/components/Login";
import { resetError } from "./features/auth/data/auth.reducer";
import { ThemeProvider } from '@mui/material/styles';
import useCustomTheme from "./useCustomTheme";

function App() {

  const navigate = useNavigate()
  const dispatch = useDispatch<any>()
  const theme = useCustomTheme()

  const loginData = useSelector((state: RootState) => state.auth.loginData)

  const errorAuth = useSelector((state: RootState) => state.auth.error)
  const errorClient = useSelector((state: RootState) => state.client.error)
  const errorJob = useSelector((state: RootState) => state.job.error)
  const errorTask = useSelector((state: RootState) => state.task.error)
  const errorBill = useSelector((state: RootState) => state.bill.error)
  const errorCash = useSelector((state: RootState) => state.cash.error)
  const errorLog = useSelector((state: RootState) => state.log.error)
  const errorRoutine = useSelector((state: RootState) => state.routine.error)
  const errorQueue = useSelector((state: RootState) => state.queue.error)

  const loadingAuth = useSelector((state: RootState) => state.auth.loading)
  const loadingClient = useSelector((state: RootState) => state.client.loading)
  const loadingJob = useSelector((state: RootState) => state.job.loading)
  const loadingTask = useSelector((state: RootState) => state.task.loading)
  const loadingBill = useSelector((state: RootState) => state.bill.loading)
  const loadingCash = useSelector((state: RootState) => state.cash.loading)
  const loadingLog = useSelector((state: RootState) => state.log.loading)
  const loadingRoutine = useSelector((state: RootState) => state.routine.loading)
  const loadingQueue = useSelector((state: RootState) => state.queue.loading)

  const [pathAndComponentList, setPathAndComponentList] = useState<Array<{ path: string, component: ReactNode }>>([])

  useEffect(() => {
    if (errorAuth) { alertError(errorAuth); dispatch(resetError()) }
    if (errorClient) alertError(errorClient)
    if (errorJob) alertError(errorJob)
    if (errorTask) alertError(errorTask)
    if (errorBill) alertError(errorBill)
    if (errorCash) alertError(errorCash)
    if (errorLog) alertError(errorLog)
    if (errorRoutine) alertError(errorRoutine)
    if (errorQueue) alertError(errorQueue)
  }, [
    dispatch,
    errorAuth,
    errorClient,
    errorJob,
    errorTask,
    errorBill,
    errorCash,
    errorLog,
    errorRoutine,
    errorQueue
  ])

  useEffect(() => {
    if (loginData.id) {
      let pathAndComponentListNew: any[] = []
      routeList.map(route => {
        if (route.child) {
          route.child.map(child => {
            pathAndComponentListNew.push({ path: route.path + child.path, component: child.component })
            return null
          })
        }
        else if (route.path) {
          pathAndComponentListNew.push({ path: route.path, component: route.component })
        }
        return null
      })
      setPathAndComponentList(pathAndComponentListNew)
    }
    else {
      navigate('/login')
    }
  }, [loginData, navigate])

  const loading = (
    loadingAuth ||
    loadingClient ||
    loadingJob ||
    loadingTask ||
    loadingBill ||
    loadingCash ||
    loadingLog ||
    loadingRoutine ||
    loadingQueue
  )

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Modal open={loading}>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100vh"}>
          <CircularProgress />
        </Box>
      </Modal>
      <Routes>
        {pathAndComponentList.map((item: any, i: number) => (
          <Route key={i} path={item.path} element={<Layout component={item.component} />} />
        ))}
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Navigate to='/dashboard' />} />
      </Routes>
    </ThemeProvider>
  )
}

export default App;
