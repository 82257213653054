import { Box, Button, Card, CardContent, IconButton, List, ListItem, ListItemButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect } from "react";
import { deleteQueue, getQueue } from "../data/queue.api";
import { Delete, SkipNext } from "@mui/icons-material";
import { createLog, getLogLatest } from "../../log/data/log.api";
import { editTask, getTaskRecent, getTaskUncomplete } from "../../task/data/task.api";
import Swal from "sweetalert2";

export default function Queue() {

    const dispatch = useDispatch<any>()

    const { queueList } = useSelector((state: RootState) => state.queue)
    const { logLatest } = useSelector((state: RootState) => state.log)

    useEffect(() => {
        dispatch(getQueue())
    }, [dispatch])

    const handleDelete = async (id: number) => {
        try {
            await dispatch(deleteQueue(id))
            await dispatch(getQueue())
        } catch (error) { }
    }

    const playIndex = async (index: number) => {
        const nextTaskId = queueList[index]?.task_id
        if (nextTaskId) {
            await dispatch(createLog({ task_id: nextTaskId })).unwrap()
        }
        else {
            await dispatch(createLog({ task_id: undefined })).unwrap()
        }
        await dispatch(getTaskUncomplete())
        await dispatch(getLogLatest())
        await dispatch(getQueue())
        await dispatch(getTaskRecent())
    }

    const handlePlay = async () => {
        if (logLatest.task_id !== undefined && queueList.map(queue => queue.task_id).includes(logLatest.task_id)) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, done it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const indexToDone = queueList.findIndex(queue => queue.task_id === logLatest.task_id)
                        await dispatch(editTask({ id: queueList[indexToDone].task_id, is_done: true })).unwrap()
                        await dispatch(deleteQueue(queueList[indexToDone].id)).unwrap()
                        playIndex(indexToDone + 1)

                    } catch (error) { }
                }
            })
        }
        else {
            playIndex(0)
        }
    }

    if (queueList.length === 0) {
        return <></>
    }

    return (
        <Card sx={{ justifyContent: "flex-start", marginTop: 3 }}>
            <CardContent>
                <List>
                    <ListItem>
                        <Button sx={{ marginBottom: 1 }} fullWidth startIcon={<SkipNext />} onClick={handlePlay}>
                            {logLatest.task_id === null || (queueList.length > 0 && queueList[0].task_id !== logLatest.task_id)
                                ? "Start Working"
                                : (queueList.length === 1 && queueList[0].task_id === logLatest.task_id)
                                    ? "Done Working"
                                    : "Next Task"
                            }
                        </Button>
                    </ListItem>
                    {queueList.map((queue, index) => (
                        <ListItemButton key={index}>
                            <Box display={"flex"} flexGrow={1} alignItems={"center"} justifyContent={"space-between"}>
                                <Typography>{queue.task.name}</Typography>
                                <IconButton onClick={() => handleDelete(queue.id)}>
                                    <Delete />
                                </IconButton>
                            </Box>
                        </ListItemButton>
                    ))}
                </List>
            </CardContent>
        </Card>
    )
}