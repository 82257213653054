import { Box, Card, CardContent, FormControl, MenuItem, Pagination, Select, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";

export default function CustomTable(props: {
    columns: Array<{
        name: string,
        key: string,
        align?: "left" | "center" | "right",
        render?: (data: any) => ReactNode
    }>,
    dataList: any[],
    actionList?: (data: any) => ReactNode
}) {

    const [rowPerPage, setRowPerPage] = useState<number>(5)
    const [page, setPage] = useState<number>(1)

    useEffect(() => {
        setPage(1)
    }, [rowPerPage])

    return (
        <Card>
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            {props.columns.map((column, i) => (
                                <TableCell key={i}>{column.name}</TableCell>
                            ))}
                            {props.actionList && (<TableCell align="center">Aksi</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.dataList.filter((_, i) => (i >= ((page - 1) * rowPerPage)) && (i < (((page - 1) * rowPerPage) + rowPerPage))).map((data, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {props.columns.map((column, colIndex) => (
                                    <TableCell key={colIndex} align={column.align}>
                                        {column.render
                                            ? column.render(data)
                                            : data[column.key]
                                        }
                                    </TableCell>
                                ))}
                                {props.actionList &&
                                    <TableCell align={"center"}>
                                        {props.actionList(data)}
                                    </TableCell>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Box marginTop={2} display={"flex"} gap={2} justifyContent={"right"} alignItems={"center"}>
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                id="demo-simple-select"
                                value={rowPerPage}
                                onChange={e => setRowPerPage(Number(e.target.value))}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Pagination count={Math.ceil(props.dataList.length / rowPerPage)} color="primary" page={page} onChange={(e, value) => setPage(value)} />
                </Box>
            </CardContent>
        </Card>
    )
}
