import { Box, Button, Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect } from "react";
import CustomTable from "../../../shared/CustomTable";
import { Delete, Edit } from "@mui/icons-material";
import Color from "../../../shared/Color";
import { InterfaceJobData } from "../data/job.interface";
import { getJob } from "../data/job.api";
import moneyParser from "../../../shared/moneyParser";
import ClientSelect from "../../client/components/ClientSelect";
import { setClientId } from "../data/job.reducer";

export default function JobTable(props: {
    onEdit?: (data: InterfaceJobData) => void,
    onDelete?: (id: number) => void
}) {

    const dispatch = useDispatch<any>()

    const { jobList, clientId } = useSelector((state: RootState) => state.job)

    useEffect(() => {
        dispatch(getJob(clientId))
    }, [dispatch, clientId])

    return (
        <Box>
            <ClientSelect value={clientId} onChange={value => dispatch(setClientId(value))} />
            <CustomTable
                columns={[
                    {
                        name: "Nama",
                        key: "name"
                    },
                    {
                        name: "Color",
                        key: "color",
                        render: (data: InterfaceJobData) => (
                            <Color color={data.color} />
                        )
                    },
                    {
                        name: "Price Additional",
                        key: "price_additional",
                        render: (data: InterfaceJobData) => (
                            <span>{moneyParser(data.price_additional)}</span>
                        )
                    },
                    {
                        name: "Active",
                        key: "is_active",
                        render: (data: InterfaceJobData) => (
                            <Checkbox checked={data.is_active} />
                        )
                    }
                ]}
                dataList={jobList}
                actionList={(props.onEdit || props.onDelete)
                    ? (data: InterfaceJobData) => (
                        <Box display={"flex"} justifyContent={"center"} gap={1}>
                            {props.onEdit && (<Button startIcon={<Edit />} color="warning" onClick={() => props.onEdit && props.onEdit(data)}>Edit</Button>)}
                            {props.onDelete && (<Button startIcon={<Delete />} color="error" onClick={() => props.onDelete && props.onDelete(data.id)}>Hapus</Button>)}
                        </Box>
                    )
                    : undefined
                }
            />
        </Box>
    )
}