import { Box, Card, CardContent, CardHeader, Tooltip, Typography, useTheme } from "@mui/material"
import { useSelector } from "react-redux"
import { RootState } from "../../../store"
import { InterfaceLogDataDetail } from "../data/log.interface"
import moment from "moment"
import secondsFormat from "../../../shared/secondsFormat"
import dataProcessToDaily from "../../../shared/dataProcessToDaily"

export default function LogTimeline() {

    const theme = useTheme()

    const { logList, statisticDateStart, statisticDateEnd } = useSelector((state: RootState) => state.log)

    const logListDaily: InterfaceLogDataDetail[][] = dataProcessToDaily(statisticDateStart, statisticDateEnd, logList)

    return (
        <Card>
            <CardContent>
                <CardHeader title="Log Timeline" />
                <Box marginTop={2}>
                    {logListDaily.map((logDailyList, i) => (
                        <Box key={i} display={"flex"} flexGrow={1} marginBottom={"4px"}>
                            {logDailyList.map((log, j) => {
                                const widthPercent = log.total_second / 86400 * 100
                                return (
                                    <Tooltip
                                        key={j}
                                        title={
                                            <Box>
                                                {log.task !== null && (
                                                    <Box>
                                                        <Typography>{log.task?.job.client.name}</Typography>
                                                        <Typography>{log.task?.job.name}</Typography>
                                                    </Box>
                                                )}
                                                {log.routine !== null && (
                                                    <Box>
                                                        <Typography>{log.routine?.name}</Typography>
                                                    </Box>
                                                )}
                                                <Typography>{moment(log.ts_created).format("HH:mm:ss")}</Typography>
                                                <Typography>{secondsFormat(log.total_second)}</Typography>
                                            </Box>
                                        }
                                    >
                                        <Box key={j} borderRadius={4} bgcolor={log.task?.job.client.color || (log.routine && theme.palette.primary.main) || theme.palette.background.default} height={8} width={`${widthPercent}%`}></Box>
                                    </Tooltip>
                                )
                            })}
                            {i === logListDaily.length - 1 && (
                                <Box width={`${100 - logList.reduce((total, log) => total + log.total_second / 86400 * 100, 0)}%`}></Box>
                            )}
                        </Box>
                    ))}
                </Box>
            </CardContent>
        </Card>
    )
}