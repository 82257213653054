import moment from "moment"
import { InterfaceLogDataDetail } from "../features/log/data/log.interface"

interface Data {
    client_name: string,
    client_color: string,
    value: number
}

export default function dataProcessToPie(logList: InterfaceLogDataDetail[], date?: string): Data[] {
    const taskList = logList.filter((log) => log.task_id !== null)
    const results: Data[] = []
    taskList.forEach((log) => {
        const index = results.findIndex((data) => data.client_name === (log.task?.job.client.name))
        const logMoment = moment(log.ts_created)
        let secondToAdd = log.total_second
        if (date !== undefined && date !== logMoment.format("YYYY-MM-DD")) {
            const secondBefore = logMoment.endOf('day').diff(moment(log.ts_created), 'seconds')
            secondToAdd = secondToAdd - secondBefore
        }
        if (index === -1) {
            results.push({
                client_name: log.task?.job.client.name || "",
                client_color: log.task?.job.client.color || "",
                value: secondToAdd
            })
        }
        else {
            results[index].value += secondToAdd
        }
    })
    return results
}