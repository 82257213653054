import { Badge, Box, Card, CardContent, CardHeader, IconButton, ListItemButton, Popover, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import Color from "../../../shared/Color";
import { Add, Archive, Done, ErrorOutline, MoreVert, PlayArrow, Queue, Unarchive } from "@mui/icons-material";
import moneyParser from "../../../shared/moneyParser";
import { editTask, getTaskRecent, getTaskUncomplete } from "../../task/data/task.api";
import alertSuccess from "../../../shared/alert/alertSuccess";
import CustomModal from "../../../shared/CustomModal";
import { createLog, getLogLatest } from "../../log/data/log.api";
import Swal from "sweetalert2";
import { InterfaceTaskData } from "../data/task.interface";
import TaskForm from "./TaskForm";
import { InitialTaskForm } from "../data/task.interface";
import { createQueue, getQueue } from "../../queue/data/queue.api";
import moment from "moment";

export function TaskUncomplete() {

    const dispatch = useDispatch<any>()
    const isMobile = useMediaQuery('(max-width:600px)');
    const theme = useTheme()

    const { taskUncomplete } = useSelector((state: RootState) => state.task)
    const { queueList } = useSelector((state: RootState) => state.queue)
    const { logLatest } = useSelector((state: RootState) => state.log)
    const { showMoney } = useSelector((state: RootState) => state.containers)

    const [taskSelected, setTaskSelected] = useState<InterfaceTaskData | undefined>(undefined)
    const [clientIndexSelected, setClientIndexSelected] = useState<number>(0)
    const [jobIdToAdd, setJobIdToAdd] = useState<number | undefined>(undefined)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [modalDetail, setModalDetail] = useState<boolean>(false)

    useEffect(() => {
        dispatch(getTaskUncomplete())
    }, [dispatch])

    const handleAddQueue = async (id: number) => {
        try {
            await dispatch(createQueue({ task_id: id, order: 0 })).unwrap()
            await dispatch(getQueue())
        } catch (error) { }
    }

    const handlePlay = async (id: number) => {
        try {
            await dispatch(createLog({ task_id: id })).unwrap()
            await dispatch(getTaskUncomplete())
            await dispatch(getLogLatest())
            await dispatch(getTaskRecent())
        } catch (error) { }
    }

    const handleDone = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, done it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(editTask({ id, is_done: true })).unwrap()
                    await alertSuccess("Task is done")
                    await dispatch(getTaskUncomplete())
                    await dispatch(getLogLatest())
                } catch (error) { }
            }
        })
    }

    const togglePending = async (id: number, is_pending: boolean) => {
        try {
            await dispatch(editTask({ id, is_pending })).unwrap()
            await dispatch(getTaskUncomplete())
        } catch (error) { }
    }

    const handleMore = (e: React.MouseEvent<HTMLElement>, task: InterfaceTaskData) => {
        setTaskSelected(task)
        setAnchorEl(anchorEl ? null : e.currentTarget)
    }

    const handleSubmitDetail = async () => {
        try {
            setModalDetail(false)
            dispatch(getTaskUncomplete())
        } catch (error) { }
    }

    const dueDateColor = (date_due: string) => {
        const dayRemains = moment(date_due).diff(moment(), 'days')
        if (dayRemains < 0) {
            return theme.palette.error.main
        }
        else if (dayRemains < 1) {
            return theme.palette.warning.main
        }
        else {
            return undefined
        }
    }

    if (taskUncomplete.length === 0) {
        return (
            <Card>
                <CardHeader
                    title="Task Uncomplete"
                />
                <CardContent>
                    <Typography variant="h6">No task uncomplete</Typography>
                </CardContent>
            </Card>
        )
    }

    return (
        <Card sx={{justifyContent: "flex-start"}}>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClick={() => setAnchorEl(null)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {taskSelected && (
                    <Box>
                        <ListItemButton onClick={() => setModalDetail(true)}>
                            Detail
                        </ListItemButton>
                        {taskSelected.is_pending
                            ? (
                                <ListItemButton onClick={() => togglePending(taskSelected.id, false)}>
                                    Cancel Pending
                                </ListItemButton>
                            )
                            : (
                                <Fragment>
                                    <ListItemButton onClick={() => togglePending(taskSelected.id, true)}>
                                        Pending
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleDone(taskSelected.id)}>
                                        Done
                                    </ListItemButton>
                                    {!queueList.map(queue => queue.task_id).includes(taskSelected.id) && (
                                        <ListItemButton onClick={() => handleAddQueue(taskSelected.id)}>
                                            Add to Queue
                                        </ListItemButton>
                                    )}
                                    <ListItemButton onClick={() => handlePlay(taskSelected.id)}>
                                        Start Working
                                    </ListItemButton>
                                </Fragment>
                            )
                        }
                    </Box>
                )}
            </Popover>
            <CustomModal
                open={modalDetail}
                onClose={() => setModalDetail(false)}
                title="Detail"
                component={modalDetail && <TaskForm mode="EDIT" data={taskSelected} onSubmit={handleSubmitDetail} />}
            />
            <CustomModal
                open={jobIdToAdd !== undefined}
                onClose={() => setJobIdToAdd(undefined)}
                title="Add Task"
                component={jobIdToAdd && <TaskForm mode="ADD" data={{ ...InitialTaskForm, job_id: jobIdToAdd }} onSubmit={() => { dispatch(getTaskUncomplete()); setJobIdToAdd(undefined) }} />}
            />
            <CardHeader
                title="Task Uncomplete"
            />
            <CardContent>
                <Tabs variant="scrollable" value={clientIndexSelected}>
                    {taskUncomplete.map((client, i) => {
                        const clientCount = (client.job_list || []).reduce((total, job) => total + job.task_list.length, 0)
                        return (
                            <Tab
                                key={i}
                                value={i}
                                onClick={() => setClientIndexSelected(i)}
                                label={
                                    <Badge badgeContent={clientCount} color={clientCount > 8 ? "error" : clientCount > 4 ? "warning" : "primary"}>
                                        <Typography sx={{ margin: 1 }}>{client.name}</Typography>
                                    </Badge>
                                }
                            />
                        )
                    })}
                </Tabs>
                {taskUncomplete[clientIndexSelected].job_list.map((job) => {
                    const taskList: InterfaceTaskData[] = JSON.parse(JSON.stringify(job.task_list))
                    return (
                        <Card key={job.id} sx={{ marginTop: 1 }}>
                            <CardContent>
                                <Box display={"flex"} justifyContent={"space-between"} marginBottom={1}>
                                    <Box display={"flex"} gap={1} alignItems={"center"}>
                                        <Color color={job.color} />
                                        <Typography variant="h6">{job.name}</Typography>
                                        <IconButton onClick={() => setJobIdToAdd(job.id)}>
                                            <Add />
                                        </IconButton>
                                    </Box>
                                    {showMoney && (
                                        <Box display={"flex"} alignItems={"center"}>
                                            <Typography variant="h6">{moneyParser(job.task_list.reduce((t, task) => t + task.price, 0))}</Typography>
                                        </Box>
                                    )}
                                </Box>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Due Date</TableCell>
                                            {showMoney && (
                                                <TableCell>Price</TableCell>
                                            )}
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {taskList
                                            .map((task) => (
                                                <TableRow key={task.id}>
                                                    <TableCell>{task.name}</TableCell>
                                                    <TableCell>
                                                        <Typography sx={{ whiteSpace: "nowrap" }} color={dueDateColor(task.date_due)}>
                                                            {task.date_due && moment(task.date_due).format("YYYY-MM-DD")}
                                                        </Typography>
                                                    </TableCell>
                                                    {showMoney && (
                                                        <TableCell>{moneyParser(task.price)}</TableCell>
                                                    )}
                                                    <TableCell>
                                                        {isMobile
                                                            ? (
                                                                <IconButton onClick={(e) => handleMore(e, task)}>
                                                                    <MoreVert />
                                                                </IconButton>
                                                            ) : (
                                                                <Fragment>
                                                                    <IconButton onClick={() => { setTaskSelected(task); setModalDetail(true) }}>
                                                                        <ErrorOutline />
                                                                    </IconButton>
                                                                    {task.is_pending
                                                                        ? (
                                                                            <IconButton onClick={() => togglePending(task.id, false)}>
                                                                                <Unarchive />
                                                                            </IconButton>
                                                                        )
                                                                        : (
                                                                            <Fragment>
                                                                                <IconButton onClick={() => togglePending(task.id, true)}>
                                                                                    <Archive />
                                                                                </IconButton>
                                                                                <IconButton onClick={() => handleDone(task.id)}>
                                                                                    <Done />
                                                                                </IconButton>
                                                                                {!queueList.map(queue => queue.task_id).includes(task.id) && (
                                                                                    <IconButton onClick={() => handleAddQueue(task.id)}>
                                                                                        <Queue />
                                                                                    </IconButton>
                                                                                )}
                                                                            </Fragment>
                                                                        )
                                                                    }
                                                                </Fragment>
                                                            )
                                                        }
                                                        {(logLatest.task_id !== task.id && !task.is_pending) && (
                                                            <IconButton onClick={() => handlePlay(task.id)}>
                                                                <PlayArrow />
                                                            </IconButton>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    )
                })}
            </CardContent>
        </Card>
    )
}