import { Box, Button, Checkbox, createTheme, FormControlLabel, Grid, ThemeProvider } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClientDetail } from "../../client/data/client.api";
import { RootState } from "../../../store";
import BillSheet from "./BillSheet";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { getBillDetail } from "../data/bill.api";
import moment from "moment";
import { editTask } from "../../task/data/task.api";

export default function BillForm(props: {
    billId?: number
}) {

    const dispatch = useDispatch<any>()
    const printRef = useRef<any>()

    const [isShowMoney, setIsShowMoney] = useState(true)
    const [isShowPoint, setIsShowPoint] = useState(false)
    const [isShowTask, setIsShowTask] = useState(true)
    const [isEdit, setIsEdit] = useState(false)

    const { clientDetail } = useSelector((state: RootState) => state.client)
    const { billDetail } = useSelector((state: RootState) => state.bill)

    useEffect(() => {
        if (props.billId) {
            dispatch(getBillDetail(props.billId)).unwrap()
                .then((response: any) => {
                    dispatch(getClientDetail(response.data.data[0].client_id))
                })
        }
    }, [dispatch, props.billId])

    const printTheme = createTheme({
        palette: {
            mode: "light",
            background: {
                default: "#F4F4F4",
                paper: "#FFFFFF",
            }
        },
        components: {
            MuiTypography: {
                defaultProps: {
                    color: "black"
                }
            },
            MuiCard: {
                defaultProps: {
                    variant: "outlined",
                    sx: {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100%"
                    }
                }
            }
        }
    })

    const handleEditPrice = async (taskId: number, value: number) => {
        await dispatch(editTask({ id: taskId, price: value }))
        await dispatch(getBillDetail(props.billId))
    }

    if (billDetail === undefined) {
        return <Box>Loading...</Box>
    }

    return (
        <Box width={"80vw"}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox checked={isShowMoney} onChange={(e) => setIsShowMoney(e.target.checked)} />} label="Show Money" />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox checked={isShowPoint} onChange={(e) => setIsShowPoint(e.target.checked)} />} label="Show Point" />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox checked={isShowTask} onChange={(e) => setIsShowTask(e.target.checked)} />} label="Show Task" />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox checked={isEdit} onChange={(e) => setIsEdit(e.target.checked)} />} label="Show Edit" />
                </Grid>
            </Grid>
            <ThemeProvider theme={printTheme}>
                <BillSheet
                    ref={printRef}
                    bill={billDetail}
                    client={clientDetail}
                    isShowMoney={isShowMoney}
                    isShowPoint={isShowPoint}
                    isShowTask={isShowTask}
                    isEdit={isEdit}
                    taxPercentage={billDetail.tax_percentage}
                    onEditPrice={handleEditPrice}
                />
            </ThemeProvider>
            <ReactToPrint content={() => printRef.current} documentTitle={`invoice ${billDetail.client.name} ${moment(billDetail.ts_created).format("YYMMDD")}`}>
                <PrintContextConsumer>
                    {({ handlePrint }) => (
                        <Button onClick={handlePrint} fullWidth sx={{ marginTop: 1 }}>Print</Button>
                    )}
                </PrintContextConsumer>
            </ReactToPrint>
        </Box>
    )
}