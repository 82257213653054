import { ArrowBackIos, ArrowForwardIos, DataArray } from "@mui/icons-material";
import { Autocomplete, Box, Button, Card, CardContent, Grid, IconButton, LinearProgress, List, ListItem, ListItemButton, ListItemText, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import CustomModal from "../../../shared/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { InterfaceTaskCreate, InitialTaskForm } from "../data/task.interface";
import { createTask, getTask } from "../data/task.api";
import alertError from "../../../shared/alert/alertError";
import moment from "moment";
import FormMoney from "../../../shared/form/FormMoney";
import JobSelect from "../../job/components/JobSelect";

export default function TaskJson() {

    const dispatch = useDispatch<any>()

    const { taskList } = useSelector((state: RootState) => state.task)

    const [modalOpen, setModalOpen] = useState(false)
    const [step, setStep] = useState(0)
    const [jsonString, setJsonString] = useState(`[]`)
    const [jsonRaw, setJsonRaw] = useState<any>([])
    const [nameKey, setNameKey] = useState("")
    const [dateStartKey, setDateStartKey] = useState("")
    const [dateDueKey, setDateDueKey] = useState("")
    const [valuesArr, setValuesArr] = useState<Array<InterfaceTaskCreate>>([])
    const [taskUploaded, setTaskUploaded] = useState(0)

    useEffect(() => {
        if (modalOpen) {
            dispatch(getTask())
        }
    }, [dispatch, modalOpen])

    useEffect(() => {
        try {
            let json = JSON.parse(jsonString)
            setJsonRaw(json)
        } catch (error) {
            alertError(error)
        }
    }, [jsonString])

    useEffect(() => {
        setValuesArr(jsonRaw.map((raw: any) => {
            return {
                ...InitialTaskForm,
                name: raw[nameKey],
                date_start: raw[dateStartKey],
                date_due: raw[dateDueKey]
            }
        }))
    }, [jsonRaw, nameKey])

    const handleClear = () => {
        setJsonRaw(jsonRaw.filter((raw: any) => !(taskList.map(task => task.name).includes(raw[nameKey]))))
    }

    const handleSubmit = async () => {
        for (const i in valuesArr) {
            let data = {
                job_id: valuesArr[i].job_id,
                name: valuesArr[i].name,
                description: "",
                price: valuesArr[i].price,
                date_start: moment(valuesArr[i].date_start).format("YYYY-MM-DD HH:mm:ss"),
                date_due: moment(valuesArr[i].date_due).format("YYYY-MM-DD HH:mm:ss")
            }
            try {
                await dispatch(createTask(data)).unwrap()
                setTaskUploaded(parseInt(i) + 1)
            } catch (error) {
                return
            }
        }
        setModalOpen(false)
        setStep(0)
        setJsonString(`[]`)
        setJsonRaw([])
        setNameKey("")
        setValuesArr([])
        setTaskUploaded(0)
        dispatch(getTask())
    }

    const components = [
        {
            name: "Import Json",
            component: (
                <div>
                    <TextField
                        label="Json"
                        margin="normal"
                        fullWidth
                        multiline
                        value={jsonString}
                        onChange={(e) => setJsonString(e.target.value)}
                    />
                </div>
            )
        },
        {
            name: "Conditioning",
            component: (
                <div>
                    {jsonRaw[0]
                        ? (
                            <Fragment>
                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    options={Object.keys(jsonRaw[0])}
                                    value={dateStartKey}
                                    onChange={(e, value) => setDateStartKey(value || "")}
                                    renderInput={(params) => <TextField {...params} margin="dense" label={"Start Date Key"} />}
                                />
                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    options={Object.keys(jsonRaw[0])}
                                    value={dateDueKey}
                                    onChange={(e, value) => setDateDueKey(value || "")}
                                    renderInput={(params) => <TextField {...params} margin="dense" label={"Due Date Key"} />}
                                />
                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    options={Object.keys(jsonRaw[0])}
                                    value={nameKey}
                                    onChange={(e, value) => setNameKey(value || "")}
                                    renderInput={(params) => <TextField {...params} margin="dense" label={"Name Key"} />}
                                />
                                <Button variant="contained" onClick={handleClear}>
                                    Clear Existing
                                </Button>
                                <List>
                                    {jsonRaw.map((j: any, i: number) => (
                                        <ListItemButton key={i}>
                                            <ListItemText
                                                sx={{ opacity: taskList.map(task => task.name).includes(j[nameKey]) ? 0.5 : 1 }}
                                                primary={j[nameKey]}
                                                secondary={j[dateStartKey] + " - " + j[dateDueKey]}
                                            />
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Fragment>
                        )
                        : (
                            "error"
                        )
                    }
                </div>
            )
        },
        {
            name: "Completing Data",
            component: (
                <div>
                    <List>
                        {valuesArr.map((values, i) => (
                            <ListItem key={i}>
                                <Card sx={{ width: "100%" }}>
                                    <CardContent>
                                        <Typography>
                                            {values.name}
                                        </Typography>
                                        <Grid container spacing={1}>
                                            <Grid item xs={9}>
                                                <JobSelect value={values.job_id} onChange={(value, valueDetail) => {
                                                    setValuesArr(valuesArr.map((values, j) => {
                                                        return {
                                                            ...values,
                                                            job_id: (i === j || values.job_id === 0) ? valueDetail?.id : values.job_id,
                                                            price: (i === j || values.price === 0) ? (valueDetail?.price_additional || 0) : values.price
                                                        }
                                                    }))
                                                }} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormMoney
                                                    label="Price"
                                                    value={values.price}
                                                    onChange={(e: any) => setValuesArr(valuesArr.map((values, j) => i === j ? { ...values, price: e.target.value } : values))}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </ListItem>
                        ))}
                    </List>
                </div>
            )
        },
        {
            name: "Preview",
            component: (
                <div>
                    <LinearProgress variant="determinate" value={(taskUploaded / valuesArr.length) * 100} />
                    <br />
                    <Button variant="contained" fullWidth onClick={handleSubmit}>
                        Submit
                    </Button>
                </div>
            )
        }
    ]

    return (
        <Fragment>
            <Button startIcon={<DataArray />} onClick={() => setModalOpen(true)}>
                Import Task
            </Button>
            <CustomModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                title="Import Task"
                component={(
                    <Box minWidth={"80vw"}>
                        <Stepper activeStep={step}>
                            {components.map(c => (
                                <Step key={c.name}>
                                    <StepLabel>{c.name}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <br />
                        {components.filter((_, i) => i === step)[0].component}
                        <Box display={"flex"} justifyContent={"flex-end"} paddingTop={1}>
                            <IconButton disabled={step <= 0} onClick={() => setStep(step - 1)}>
                                <ArrowBackIos />
                            </IconButton>
                            <IconButton disabled={step >= (components.length - 1)} onClick={() => setStep(step + 1)}>
                                <ArrowForwardIos />
                            </IconButton>
                        </Box>
                    </Box>
                )}
            />
        </Fragment>
    )
}