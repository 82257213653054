import { Box, Button, Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import FormText from "../../../shared/form/FormText";
import { useDispatch, useSelector } from "react-redux";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { createCash, getCashByTask } from "../data/cash.api";
import { InitialCashForm, InterfaceCashForm } from "../data/cash.interface";
import FormMoney from "../../../shared/form/FormMoney";
import moment from "moment";
import moneyParser from "../../../shared/moneyParser";
import { RootState } from "../../../store";

export default function CashAdd(props: { taskId?: number, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const { cashListByTask } = useSelector((state: RootState) => state.cash)
    const [values, setValues] = useState<InterfaceCashForm>(InitialCashForm)

    useEffect(() => {
        if (props.taskId) {
            dispatch(getCashByTask(props.taskId))
        }
    }, [dispatch, props.taskId])

    const handleChangeValues = (e: ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target
        let value = e.target.value
        setValues(last => ({
            ...last,
            [name]: value
        }))
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            await dispatch(createCash({ ...values, task_id: props.taskId || 0 })).unwrap()
            await alertSuccess("Berhasil menyimpan data")
            setValues(InitialCashForm)
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box>
            {props.taskId && (
                <Card sx={{ marginBottom: 1 }}>
                    <CardContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Time</TableCell>
                                    <TableCell>Value</TableCell>
                                    <TableCell>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cashListByTask.map((cash) => (
                                    <TableRow key={cash.id}>
                                        <TableCell>{moment(cash.ts_created).format("YYYY-MM-DD")}</TableCell>
                                        <TableCell align="right">{moneyParser(cash.value)}</TableCell>
                                        <TableCell>{cash.description}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Total</TableCell>
                                    <TableCell align="right">{moneyParser(cashListByTask.reduce((total, cash) => total + cash.value, 0))}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </CardContent>
                </Card>
            )}
            <form onSubmit={handleSubmit}>
                <FormMoney label="Value" name="value" value={values.value} onChange={handleChangeValues} />
                <FormText label="Description" name="description" value={values.description} onChange={handleChangeValues} />
                <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button type="submit">Simpan</Button>
                </Box>
            </form>
        </Box>
    )
}