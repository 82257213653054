import { Box, Button } from "@mui/material";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { InitialTaskForm, InterfaceTaskForm } from "../data/task.interface";
import FormText from "../../../shared/form/FormText";
import { useDispatch } from "react-redux";
import { createTask, editTask } from "../data/task.api";
import alertSuccess from "../../../shared/alert/alertSuccess";
import FormDate from "../../../shared/form/FormDate";
import FormMoney from "../../../shared/form/FormMoney";
import FormCheck from "../../../shared/form/FormCheck";
import JobSelect from "../../job/components/JobSelect";
import CashAdd from "../../cash/components/CashAdd";

export default function TaskForm(props: { mode: "ADD" | "EDIT", data?: InterfaceTaskForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const [values, setValues] = useState<InterfaceTaskForm>(InitialTaskForm)

    useEffect(() => {
        if (props.mode === "EDIT" && props.data) {
            setValues(props.data)
        }
        else if (props.mode === "ADD") {
            setValues({
                ...InitialTaskForm,
                job_id: props.data?.job_id || 0
            })
        }
    }, [props.mode, props.data])

    const handleChangeValues = (e: ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target
        let value = name === "is_done"
            ? (e.target.value === "1" ? true : false)
            : name === "price"
                ? parseInt(e.target.value)
                : e.target.value
        setValues(last => ({
            ...last,
            [name]: value
        }))
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createTask(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editTask(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            setValues(InitialTaskForm)
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <JobSelect value={values.job_id} onChange={(value) => setValues(last => ({ ...last, job_id: value || 0 }))} />
                <FormText label="Nama" name="name" value={values.name} required onChange={handleChangeValues} />
                <FormText label="Description" name="description" value={values.description} onChange={handleChangeValues} />
                <FormMoney label="Price" name="price" value={values.price} required onChange={handleChangeValues} />
                <FormDate label="Date Due" name="date_due" value={values.date_due} onChange={handleChangeValues} />
                <FormCheck label="Done" name="is_done" value={values.is_done} onChange={handleChangeValues} />
                <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button type="submit">Simpan</Button>
                </Box>
            </form>
            {props.mode === "EDIT" && (
                <Box marginTop={1}>
                    <CashAdd taskId={props.data?.id} onSubmit={props.onSubmit} />
                </Box>
            )}
        </Box>
    )
}