import { configureStore } from '@reduxjs/toolkit'

import authReducer from './features/auth/data/auth.reducer'
import containersReducer from './containers/data/containers.reducer';
import taskReducer from './features/task/data/task.reducer';
import jobReducer from './features/job/data/job.reducer';
import clientReducer from './features/client/data/client.reducer';
import billReducer from './features/bill/data/bill.reducer';
import cashReducer from './features/cash/data/cash.reducer';
import logReducer from './features/log/data/log.reducer';
import routineReducer from './features/routine/data/routine.reducer';
import queueReducer from './features/queue/data/queue.reducer';

const authStorage = localStorage.getItem("authState")

export const store = configureStore({
    reducer: {
        auth: authReducer,
        containers: containersReducer,
        task: taskReducer,
        job: jobReducer,
        client: clientReducer,
        bill: billReducer,
        cash: cashReducer,
        log: logReducer,
        routine: routineReducer,
        queue: queueReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }),
    preloadedState: {
        auth: typeof (authStorage) === "string" ? JSON.parse(authStorage) : undefined
    }
})

store.subscribe(() => {
    const serializedState = JSON.stringify(store.getState().auth)
    localStorage.setItem('authState', serializedState)
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch