import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import FormText from "../../../shared/form/FormText";
import { useDispatch } from "react-redux";
import { createRoutine, editRoutine, getRoutine } from "../data/routine.api";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { InitialRoutineForm, InterfaceRoutineForm } from "../data/routine.interface";

export default function RoutineForm(props: { mode: "ADD" | "EDIT", data?: InterfaceRoutineForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const [values, setValues] = useState<InterfaceRoutineForm>(InitialRoutineForm)

    useEffect(() => {
        if (props.data !== undefined) {
            setValues(props.data)
        }
    }, [props.data])

    const handleChangeValues = (e: ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target
        let value = name === "is_active" ? (e.target.value === "1" ? true : false) : e.target.value
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createRoutine(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editRoutine(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            setValues(InitialRoutineForm)
            dispatch(getRoutine())
            props.onSubmit()
        } catch (error) { }
    }

    const dayList = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"]

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <FormText label="Nama" name="name" value={values.name} required onChange={handleChangeValues} />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Hari</TableCell>
                            <TableCell>Menit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dayList.map((day, i) => (
                            <TableRow key={i}>
                                <TableCell>{day}</TableCell>
                                <TableCell>
                                    <TextField
                                        type="number"
                                        value={values.minute_day_list[i] || 0}
                                        onChange={(e) => setValues({ ...values, minute_day_list: values.minute_day_list.map((item, index) => index === i ? parseInt(e.target.value) : item) })}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button type="submit">Simpan</Button>
                </Box>
            </form>
        </Box>
    )
}