import { Menu } from "@mui/icons-material";
import { IconButton, Toolbar } from "@mui/material";

export default function TopBar(props: { onMenuClick: () => void }) {
    return (
        <Toolbar>
            <IconButton
                size="large"
                edge="start"
                onClick={props.onMenuClick}
            >
                <Menu />
            </IconButton>
        </Toolbar>
    )
}