import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect } from "react";
import CustomTable from "../../../shared/CustomTable";
import { Delete, Edit } from "@mui/icons-material";
import { InterfaceCashData } from "../data/cash.interface";
import { getCash } from "../data/cash.api";
import moneyParser from "../../../shared/moneyParser";
import moment from "moment";

export default function CashTable(props: {
    onEdit?: (data: InterfaceCashData) => void,
    onDelete?: (id: number) => void
}) {

    const dispatch = useDispatch<any>()

    const { cashList } = useSelector((state: RootState) => state.cash)

    useEffect(() => {
        dispatch(getCash())
    }, [dispatch])

    return (
        <Box>
            <CustomTable
                columns={[
                    {
                        name: "Date",
                        key: "date",
                        render: (data: InterfaceCashData) => moment(data.ts_created).format("YYYY-MM-DD")
                    },
                    {
                        name: "Client",
                        key: "task",
                        render: (data: InterfaceCashData) => data.task.job.client.name
                    },
                    {
                        name: "Job",
                        key: "task",
                        render: (data: InterfaceCashData) => data.task.job.name
                    },
                    {
                        name: "Task",
                        key: "task",
                        render: (data: InterfaceCashData) => data.task.name
                    },
                    {
                        name: "Value",
                        key: "value",
                        render: (data: InterfaceCashData) => moneyParser(data.value)
                    },
                    {
                        name: "Description",
                        key: "description"
                    }
                ]}
                dataList={cashList}
                actionList={(props.onEdit || props.onDelete)
                    ? (data: InterfaceCashData) => (
                        <Box display={"flex"} justifyContent={"center"} gap={1}>
                            {props.onEdit && (<Button startIcon={<Edit />} color="warning" onClick={() => props.onEdit && props.onEdit(data)}>Edit</Button>)}
                            {props.onDelete && (<Button startIcon={<Delete />} color="error" onClick={() => props.onDelete && props.onDelete(data.id)}>Hapus</Button>)}
                        </Box>
                    )
                    : undefined
                }
            />
        </Box>
    )
}