import { Box, Button, Container, Typography } from "@mui/material";
import TaskTable from "../../features/task/components/TaskTable";
import { Add } from "@mui/icons-material";
import CustomModal from "../../shared/CustomModal";
import { useState } from "react";
import { InterfaceTaskForm } from "../../features/task/data/task.interface";
import { useDispatch } from "react-redux";
import alertDelete from "../../shared/alert/alertDelete";
import { deleteTask, getTask } from "../../features/task/data/task.api";
import alertSuccess from "../../shared/alert/alertSuccess";
import TaskForm from "../../features/task/components/TaskForm";
import TaskJson from "../../features/task/components/TaskJson";

export default function TaskPage() {

    const dispatch = useDispatch<any>()

    const [modalAdd, setModalAdd] = useState<boolean>(false)
    const [dataSelected, setDataSelected] = useState<InterfaceTaskForm | undefined>(undefined)

    const handleDelete = (id: number) => {
        alertDelete()
            .then(async isConfirmed => {
                if (isConfirmed) {
                    try {
                        await dispatch(deleteTask(id))
                        await alertSuccess("Berhasil menghapus data")
                        await dispatch(getTask())
                    } catch (error) { }
                }
            })
    }

    return (
        <Container>
            <CustomModal
                open={modalAdd}
                title="Add Task"
                onClose={() => setModalAdd(false)}
                component={<TaskForm mode="ADD" onSubmit={() => setModalAdd(false)} />}
            />
            <CustomModal
                open={Boolean(dataSelected)}
                title="Edit Task"
                onClose={() => setDataSelected(undefined)}
                component={<TaskForm mode="EDIT" data={dataSelected} onSubmit={() => setDataSelected(undefined)} />}
            />
            <Typography variant="h3">Task</Typography>
            <Box marginTop={1} display={"flex"} justifyContent={"flex-end"} gap={1}>
                <TaskJson />
                <Button startIcon={<Add />} onClick={() => setModalAdd(true)}>Add</Button>
            </Box>
            <br />
            <TaskTable onEdit={setDataSelected} onDelete={handleDelete} />
        </Container>
    )
}