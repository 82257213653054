import { Container, Typography } from "@mui/material";
import CashTable from "../../features/cash/components/CashTable";
import CustomModal from "../../shared/CustomModal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import alertDelete from "../../shared/alert/alertDelete";
import { deleteCash, getCash } from "../../features/cash/data/cash.api";
import alertSuccess from "../../shared/alert/alertSuccess";
import CashForm from "../../features/cash/components/CashForm";
import { InterfaceCashForm } from "../../features/cash/data/cash.interface";

export default function CashPage() {

    const dispatch = useDispatch<any>()

    const [dataSelected, setDataSelected] = useState<InterfaceCashForm | undefined>(undefined)

    const handleDelete = (id: number) => {
        alertDelete()
            .then(async isConfirmed => {
                if (isConfirmed) {
                    try {
                        await dispatch(deleteCash(id))
                        await alertSuccess("Berhasil menghapus data")
                        await dispatch(getCash())
                    } catch (error) { }
                }
            })
    }

    return (
        <Container>
            <CustomModal
                open={Boolean(dataSelected)}
                title="Edit Cash"
                onClose={() => setDataSelected(undefined)}
                component={<CashForm mode="EDIT" data={dataSelected} onSubmit={() => setDataSelected(undefined)} />}
            />
            <Typography variant="h3">Cash</Typography>
            <br />
            <CashTable onEdit={setDataSelected} onDelete={handleDelete} />
        </Container>
    )
}