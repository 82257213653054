import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

export default function PriceButton(props: { value: number, step: number, onChange: (value: number) => void }) {

    const valueDecrease = props.value - props.step

    return (
        <Box display={"flex"} alignItems={"center"}>
            <Box>
                <IconButton disabled={props.value === 0} onClick={() => props.onChange(valueDecrease < 0 ? 0 : valueDecrease)}>
                    <KeyboardArrowDown />
                </IconButton>
            </Box>
            <Box>
                <IconButton onClick={() => props.onChange(props.value + props.step)}>
                    <KeyboardArrowUp />
                </IconButton>
            </Box>
        </Box>
    )
}