import { Box, Button } from "@mui/material";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import FormText from "../../../shared/form/FormText";
import { useDispatch } from "react-redux";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { createCash, editCash, getCash } from "../data/cash.api";
import { InitialCashForm, InterfaceCashForm } from "../data/cash.interface";
import FormMoney from "../../../shared/form/FormMoney";

export default function CashForm(props: { mode: "ADD" | "EDIT", data?: InterfaceCashForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const [values, setValues] = useState<InterfaceCashForm>(InitialCashForm)

    useEffect(() => {
        if (props.data !== undefined) {
            setValues(props.data)
        }
    }, [props.data])

    const handleChangeValues = (e: ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target
        let value = name === "is_active" ? (e.target.value === "1" ? true : false) : e.target.value
        setValues(last => ({
            ...last,
            [name]: value
        }))
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createCash(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editCash(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            setValues(InitialCashForm)
            dispatch(getCash())
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <FormMoney label="Value" name="value" value={values.value} onChange={handleChangeValues} />
                <FormText label="Description" name="description" value={values.description} onChange={handleChangeValues} />
                <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button type="submit">Simpan</Button>
                </Box>
            </form>
        </Box>
    )
}