import { createSlice } from '@reduxjs/toolkit'
import { createBill, deleteBill, getBill, getBillDetail, payBill } from './bill.api'
import { InterfaceBillData } from './bill.interface'

export interface BillState {
    loading: boolean,
    error: any,
    billList: InterfaceBillData[],
    billDetail?: InterfaceBillData
    clientId?: number
}

const initialState: BillState = {
    loading: false,
    error: undefined,
    billList: [],
    clientId: undefined
}

export const billSlice = createSlice({
    name: 'bill',
    initialState,
    reducers: {
        setClientId: (state, action) => {
            state.clientId = action.payload
        }
    },
    extraReducers(builder) {

        builder.addCase(getBill.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getBill.fulfilled, (state, action) => {
            state.loading = false
            state.billList = action.payload.data.data
        })
        builder.addCase(getBill.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(getBillDetail.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getBillDetail.fulfilled, (state, action) => {
            state.loading = false
            state.billDetail = action.payload.data.data[0]
        })
        builder.addCase(getBillDetail.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(createBill.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createBill.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createBill.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(deleteBill.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteBill.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteBill.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(payBill.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(payBill.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(payBill.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
    },
})

export const { setClientId } = billSlice.actions

export default billSlice.reducer