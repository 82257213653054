import Box from '@mui/material/Box';
import Content from './Content';
import Sidebar from './Sidebar';
import { ReactNode, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import TopBar from './TopBar';

export default function Layout(props: { component: ReactNode }) {

    const isMobile = useMediaQuery('(max-width:600px)');

    const [openMenu, setOpenMenu] = useState(false);

    return (
        <Box display={"flex"} flexDirection={isMobile ? "column" : "row"}>
            {isMobile
                ? <TopBar onMenuClick={() => setOpenMenu(true)} />
                : <Sidebar />
            }
            <Sidebar open={openMenu} onClose={() => setOpenMenu(false)} />
            <Content component={props.component} />
        </Box>
    )
}