import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";
import { RootState } from "../../../store";
import { InterfaceBillCreate } from "./bill.interface";

export const getBill = createAsyncThunk('getBill', async (_, thunkAPI) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/bill'
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const getBillDetail = createAsyncThunk('getBillDetail', async (billId: number | undefined, thunkAPI) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/bill',
            params: { id: billId }
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const createBill = createAsyncThunk('createBill', async (data: InterfaceBillCreate, thunkAPI) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/bill',
            data: data
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const deleteBill = createAsyncThunk('deleteBill', async (id: number, thunkAPI) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/bill',
            params: {
                id: id
            }
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const payBill = createAsyncThunk('payBill', async (data: { id: number, date_paid: string }, thunkAPI) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/bill/pay',
            data: data
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})