import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";
import { RootState } from "../../../store";
import { InterfaceCashCreate, InterfaceCashEdit } from "./cash.interface";

export const getCash = createAsyncThunk('getCash', async (_, thunkAPI) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/cash'
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})


export const getCashByTask = createAsyncThunk('getCashByTask', async (taskId: number, thunkAPI) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/cash/task/' + taskId,
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const createCash = createAsyncThunk('createCash', async (data: InterfaceCashCreate, thunkAPI) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/cash',
            data: data
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const editCash = createAsyncThunk('editCash', async (data: InterfaceCashEdit, thunkAPI) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/cash',
            data: data
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const deleteCash = createAsyncThunk('deleteCash', async (id: number, thunkAPI) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/cash',
            params: {
                id: id
            }
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})