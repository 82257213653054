import { Card, CardContent, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTaskRecent } from "../data/task.api";
import { RootState } from "../../../store";
import Color from "../../../shared/Color";
import { createLog, getLogLatest } from "../../log/data/log.api";

export default function TaskRecent() {

    const dispatch = useDispatch<any>()

    const { taskRecentList } = useSelector((state: RootState) => state.task)

    useEffect(() => {
        dispatch(getTaskRecent())
    }, [dispatch])

    const handlePlay = async (id: number) => {
        await dispatch(createLog({ task_id: id })).unwrap()
        await dispatch(getLogLatest()).unwrap()
        await dispatch(getTaskRecent())
    }

    return (
        <Card sx={{ marginTop: 3 }}>
            <CardContent>
                <List disablePadding>
                    {taskRecentList.map((task, index) => {
                        return (
                            <ListItemButton key={index} onClick={() => handlePlay(task.id)}>
                                <ListItemIcon>
                                    <Color color={task.job.color} />
                                </ListItemIcon>
                                <ListItemText primary={task.name} />
                            </ListItemButton>
                        )
                    })}
                </List>
            </CardContent>
        </Card>
    )
}