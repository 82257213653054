import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import routeList from '../../routeList';
import { useNavigate } from "react-router-dom"
import { useState } from 'react';
import { AttachMoney, DarkMode, ExpandLess, ExpandMore, LightMode, Logout, MoneyOff } from '@mui/icons-material';
import { Button, ButtonGroup, Collapse, IconButton, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoginData } from '../../features/auth/data/auth.reducer';
import { setTheme, toggleMoney } from '../data/containers.reducer';
import { RootState } from '../../store';

const drawerWidth = 240;

export default function Sidebar(props: { open?: boolean, onClose?: () => void }) {

    const navigate = useNavigate()
    const theme = useTheme()
    const dispatch = useDispatch<any>()

    const themeSelected = useSelector((state: RootState) => state.containers).theme
    const { showMoney } = useSelector((state: RootState) => state.containers)

    const [expanded, setExpanded] = useState("")

    const handleClickMenu = (menu: any, child: any = null) => {
        if (Boolean(child)) {
            navigate(menu.path + child.path)
            props.onClose && props.onClose()
        }
        else {
            if (!menu.child) {
                navigate(menu.path)
                props.onClose && props.onClose()
            }
            else if (expanded === menu.path) {
                setExpanded("")
            }
            else {
                setExpanded(menu.path)
            }
        }
    }

    const handleLogout = () => {
        dispatch(resetLoginData())
        window.location.replace("/")
    }

    const openedUrl = window.location.hash.substring(1)

    return (
        <Drawer
            open={props.open === undefined ? true : props.open}
            variant={props.open === undefined ? "permanent" : "temporary"}
            onClose={props.onClose}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <Box sx={{ overflow: 'auto' }} display={"flex"} flexGrow={1} flexDirection={"column"} justifyContent={"space-between"}>
                <List>
                    {routeList
                        .map((menu, i) => (
                            <div key={i}>
                                <ListItemButton sx={(menu.child === undefined && openedUrl === menu.path) || (expanded !== menu.path && menu.child?.map(child => menu.path + child.path).includes(openedUrl)) ? { color: theme.palette.primary.main } : {}} onClick={() => handleClickMenu(menu)}>
                                    <ListItemIcon sx={(menu.child === undefined && openedUrl === menu.path) || (expanded !== menu.path && menu.child?.map(child => menu.path + child.path).includes(openedUrl)) ? { color: theme.palette.primary.main } : {}}>
                                        {menu.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={menu.name} />
                                    {menu.child && (expanded === menu.path ? <ExpandLess /> : <ExpandMore />)}
                                </ListItemButton>
                                {menu.child && (
                                    <Collapse in={expanded === menu.path}>
                                        <List disablePadding>
                                            {menu.child
                                                .map((child: any, i: number) => (
                                                    <ListItemButton key={i} sx={openedUrl === menu.path + child.path ? { color: theme.palette.primary.main } : {}} onClick={() => handleClickMenu(menu, child)}>
                                                        <ListItemIcon></ListItemIcon>
                                                        <ListItemText primary={child.name} />
                                                    </ListItemButton>
                                                ))}
                                        </List>
                                    </Collapse>
                                )}
                            </div>
                        ))}
                </List>
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={2} padding={2}>
                    <ButtonGroup>
                        <IconButton onClick={() => dispatch(setTheme("dark"))}>
                            <DarkMode color={(themeSelected === "dark" || theme.palette.mode === "dark") ? "primary" : undefined} />
                        </IconButton>
                        <IconButton onClick={() => dispatch(setTheme("light"))}>
                            <LightMode color={(themeSelected === "light" || theme.palette.mode === "light") ? "primary" : undefined} />
                        </IconButton>
                        <IconButton onClick={() => dispatch(toggleMoney())}>
                            {showMoney
                                ? <MoneyOff />
                                : <AttachMoney />
                            }
                        </IconButton>
                    </ButtonGroup>
                    <Button color='primary' variant='outlined' startIcon={<Logout />} onClick={handleLogout}>Logout</Button>
                </Box>
            </Box>
        </Drawer>
    )
}