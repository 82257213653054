import { createSlice } from '@reduxjs/toolkit'
import { createLog, getLogDate, getLogLatest, getLogDay, updateLog } from './log.api'
import { InitialLogDataDetail, InterfaceLogDataDetail } from './log.interface'
import moment from 'moment'

export interface LogState {
    loading: boolean,
    error: any,
    logLatest: InterfaceLogDataDetail,
    logTodayList: InterfaceLogDataDetail[],
    logList: InterfaceLogDataDetail[],
    statisticDateStart: string,
    statisticDateEnd: string,
}

const initialState: LogState = {
    loading: false,
    error: undefined,
    logLatest: InitialLogDataDetail,
    logTodayList: [],
    logList: [],
    statisticDateStart: moment().add(-6, 'days').format('YYYY-MM-DD'),
    statisticDateEnd: moment().format('YYYY-MM-DD'),
}

export const logSlice = createSlice({
    name: 'log',
    initialState,
    reducers: {
        setStatisticDateStart: (state, action) => {
            state.statisticDateStart = action.payload
        },
        setStatisticDateEnd: (state, action) => {
            state.statisticDateEnd = action.payload
        }
    },
    extraReducers(builder) {

        builder.addCase(getLogLatest.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getLogLatest.fulfilled, (state, action) => {
            state.loading = false
            state.logLatest = action.payload.data.data
        })
        builder.addCase(getLogLatest.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(getLogDate.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getLogDate.fulfilled, (state, action) => {
            state.loading = false
            state.logList = action.payload.data.data
        })
        builder.addCase(getLogDate.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(getLogDay.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getLogDay.fulfilled, (state, action) => {
            state.loading = false
            state.logTodayList = action.payload.data.data
        })
        builder.addCase(getLogDay.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(createLog.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createLog.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createLog.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(updateLog.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(updateLog.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(updateLog.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

    },
})

export const { setStatisticDateStart, setStatisticDateEnd } = logSlice.actions;

export default logSlice.reducer