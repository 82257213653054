import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";
import { RootState } from "../../../store";
import { InterfaceJobCreate, InterfaceJobEdit } from "./job.interface";

export const getJob = createAsyncThunk('getJob', async (clientId: number | undefined, thunkAPI) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/job',
            params: { client_id: clientId }
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const createJob = createAsyncThunk('createJob', async (data: InterfaceJobCreate, thunkAPI) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/job',
            data: data
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const editJob = createAsyncThunk('editJob', async (data: InterfaceJobEdit, thunkAPI) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/job',
            data: data
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const deleteJob = createAsyncThunk('deleteJob', async (id: number, thunkAPI) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/job',
            params: {
                id: id
            }
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})