import { Money, Source, Summarize, TrendingUp } from "@mui/icons-material";

import DashboardPage from "./pages/DashboardPage";
import StatisticPage from "./pages/StatisticPage";
import RoutinePage from "./pages/master/RoutinePage";
import TaskPage from "./pages/master/TaskPage";
import JobPage from "./pages/master/JobPage";
import ClientPage from "./pages/master/ClientPage";
import ReadyToBillPage from "./pages/finance/ReadyToBillPage";
import BillPage from "./pages/finance/BillPage";
import CashPage from "./pages/finance/CashPage";

const routeList = [
    {
        name: "Dashboard",
        path: "/dashboard",
        icon: <Summarize />,
        component: <DashboardPage />
    },
    {
        name: "Statistic",
        path: "/statistic",
        icon: <TrendingUp />,
        component: <StatisticPage />
    },
    {
        name: "Finance",
        path: "/finance",
        icon: <Money />,
        child: [
            {
                name: "Ready to Bill",
                path: "/ready",
                component: <ReadyToBillPage />
            },
            {
                name: "Bill",
                path: "/bill",
                component: <BillPage />
            },
            {
                name: "Cash",
                path: "/cash",
                component: <CashPage />
            }
        ]
    },
    {
        name: "Master",
        path: "/master",
        icon: <Source />,
        child: [
            {
                name: "Routine",
                path: "/routine",
                component: <RoutinePage />
            },
            {
                name: "Task",
                path: "/task",
                component: <TaskPage />
            },
            {
                name: "Job",
                path: "/job",
                component: <JobPage />
            },
            {
                name: "Client",
                path: "/client",
                component: <ClientPage />
            }
        ]
    }
]

export default routeList 