import { Box, Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { Fragment, Ref, forwardRef } from "react";
import moment from "moment";
import signature from "../../../assets/images/signature.png";
import { InterfaceClientDataDetail } from "../../client/data/client.interface";
import moneyParser from "../../../shared/moneyParser";
import { InterfaceBillData } from "../data/bill.interface";
import PriceButton from "../../../shared/PriceButton";

const ClientBillSheet = (
    props: {
        bill: InterfaceBillData,
        client: InterfaceClientDataDetail
        taxPercentage: number,
        isShowTask: boolean,
        isShowPoint: boolean,
        isShowMoney: boolean,
        isEdit: boolean,
        onEditPrice: (taskId: number, price: number) => void,
    },
    ref: Ref<HTMLDivElement>
) => {

    const theme = useTheme()

    const totalTaskPrice = props.bill.task_list.reduce((total, task) => total + task.price, 0)
    const totalTaskDownPayment = props.bill.task_list.flatMap(task => task.cash_list).reduce((total, cash) => total + cash.value, 0)

    return (
        <Box ref={ref} padding={2} bgcolor={theme.palette.background.paper}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h2">
                        Invoice
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>
                        From :
                    </Typography>
                    <Typography fontWeight={"bold"}>
                        Fajar Yuda Pratama
                    </Typography>
                    <Typography>
                        0851-5656-3252
                    </Typography>
                    <Typography>
                        fajaryoedha@gmail.com
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>
                        To :
                    </Typography>
                    <Typography fontWeight={"bold"}>
                        {props.bill.client.name}
                    </Typography>
                    <Typography>
                        {props.bill.client.phone}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <table>
                        <tbody>
                            <tr>
                                <td><Typography>Invoice No</Typography></td>
                                <td><Typography>:</Typography></td>
                                <td><Typography>{moment(props.bill.ts_created).format("YYMMDD")}</Typography></td>
                            </tr>
                            <tr>
                                <td><Typography>Invoice Date</Typography></td>
                                <td><Typography>:</Typography></td>
                                <td><Typography>{moment(props.bill.ts_created).format("DD/MM/YYYY")}</Typography></td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
            </Grid>
            <Typography variant="h6" sx={{ marginTop: 3 }}>
                Items
            </Typography>
            <Card>
                <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                {props.isShowTask && (<TableCell sx={{ whiteSpace: "nowrap" }}>Date Created</TableCell>)}
                                <TableCell>{props.isShowTask ? "Task" : "Item"}</TableCell>
                                {props.isShowPoint && (<TableCell align="center">Point</TableCell>)}
                                {props.isShowMoney && (<TableCell align="center">Price</TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.client.job_list.filter(job => props.bill.task_list.map(task => task.job_id).includes(job.id)).map((job, jobIndex) => (
                                <Fragment key={job.id}>
                                    <TableRow>
                                        <TableCell colSpan={props.isShowTask ? 2 : 1} sx={{ fontWeight: props.isShowTask ? "bold" : "normal" }}>{jobIndex + 1}</TableCell>
                                        <TableCell sx={{ fontWeight: props.isShowTask ? "bold" : "normal" }}>{job.name}</TableCell>
                                        {props.isShowPoint && (<TableCell align="right" sx={{ fontWeight: props.isShowTask ? "bold" : "normal" }}>{props.bill.task_list.filter(task => task.job_id === job.id).reduce((total, task) => total + (task.price / job.price_additional), 0)}</TableCell>)}
                                        {props.isShowMoney && (<TableCell align="right" sx={{ fontWeight: props.isShowTask ? "bold" : "normal" }}>{moneyParser(props.bill.task_list.filter(task => task.job_id === job.id).reduce((total, task) => total + task.price, 0))}</TableCell>)}
                                    </TableRow>
                                    {props.isShowTask && (
                                        props.bill.task_list.filter(task => task.job_id === job.id).map((task, i) => (
                                            <TableRow key={task.id}>
                                                <TableCell>{i + 1}</TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }}>{moment(task.ts_created).format("YYYY-MM-DD")}</TableCell>
                                                <TableCell>{task.name}</TableCell>
                                                {props.isShowPoint && (<TableCell align="right">{task.price / job.price_additional}</TableCell>)}
                                                {props.isShowMoney && (
                                                    <TableCell align="right">
                                                        <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} gap={1}>
                                                            <Typography>{moneyParser(task.price)}</Typography>
                                                            {props.isEdit && (<PriceButton value={task.price} step={job.price_additional} onChange={(value) => props.onEditPrice(task.id, value)} />)}
                                                        </Box>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))
                                    )}
                                </Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
            {props.bill.task_list.some(task => task.cash_list.length > 0) && (
                <Box marginTop={3}>
                    <Typography variant="h6">
                        Down Payments
                    </Typography>
                    <Card>
                        <CardContent>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap" }}>Date</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell align="center">Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.client.job_list.filter(job => props.bill.task_list.map(task => task.job_id).includes(job.id)).map((job, jobIndex) => (
                                        <Fragment key={job.id}>
                                            <TableRow>
                                                <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>{jobIndex + 1}</TableCell>
                                                <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>{job.name}</TableCell>
                                            </TableRow>
                                            {props.bill.task_list.filter(task => task.job_id === job.id).flatMap(task => task.cash_list).map((cash, i) => (
                                                <TableRow key={cash.id}>
                                                    <TableCell>{i + 1}</TableCell>
                                                    <TableCell sx={{ whiteSpace: "nowrap" }}>{moment(cash.ts_created).format("YYYY-MM-DD")}</TableCell>
                                                    <TableCell>{cash.description}</TableCell>
                                                    {props.isShowMoney && (<TableCell align="right">{moneyParser(cash.value)}</TableCell>)}
                                                </TableRow>
                                            ))}
                                        </Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Box>
            )}
            {props.isShowMoney && (
                <Grid container spacing={2} marginTop={1}>
                    <Grid item sm={8}></Grid>
                    <Grid item sm={4}>
                        <Card sx={{ height: "auto" }}>
                            <CardContent>
                                <Table>
                                    {props.taxPercentage === 0
                                        ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Subtotal</TableCell>
                                                    <TableCell>:</TableCell>
                                                    <TableCell align="right">{moneyParser(totalTaskPrice)}</TableCell>
                                                </TableRow>
                                                {totalTaskDownPayment > 0 && (
                                                    <TableRow>
                                                        <TableCell>Total Down Payment</TableCell>
                                                        <TableCell>:</TableCell>
                                                        <TableCell align="right">{moneyParser(totalTaskDownPayment)}</TableCell>
                                                    </TableRow>
                                                )}
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: "bold" }}>Total</TableCell>
                                                    <TableCell sx={{ fontWeight: "bold" }}>:</TableCell>
                                                    <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(totalTaskPrice - totalTaskDownPayment)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )
                                        : (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Subtotal</TableCell>
                                                    <TableCell>:</TableCell>
                                                    <TableCell align="right">{moneyParser(totalTaskPrice)}</TableCell>
                                                </TableRow>
                                                {totalTaskDownPayment > 0 && (
                                                    <TableRow>
                                                        <TableCell>Total Down Payment</TableCell>
                                                        <TableCell>:</TableCell>
                                                        <TableCell align="right">{moneyParser(totalTaskDownPayment)}</TableCell>
                                                    </TableRow>
                                                )}
                                                <TableRow>
                                                    <TableCell sx={{ whiteSpace: "nowrap" }}>Tax ({props.taxPercentage}%)</TableCell>
                                                    <TableCell>:</TableCell>
                                                    <TableCell align="right">{moneyParser(
                                                        totalTaskPrice * (props.taxPercentage / 100)
                                                    )}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: "bold" }}>Total</TableCell>
                                                    <TableCell sx={{ fontWeight: "bold" }}>:</TableCell>
                                                    <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(
                                                        (totalTaskPrice - totalTaskDownPayment) -
                                                        (totalTaskPrice * (props.taxPercentage / 100))
                                                    )}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )
                                    }
                                </Table>
                            </CardContent>
                        </Card>
                        <Box marginTop={1} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                            <img src={signature} alt="signature" width={150} />
                            <Typography>
                                Fajar Yuda Pratama
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Box>
    )
}

export default forwardRef(ClientBillSheet)