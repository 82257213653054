import { useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootState } from './store';

export default function useCustomTheme() {

    const themeSelected = useSelector((state: RootState) => state.containers).theme
    const systemDark = useMediaQuery('(prefers-color-scheme: dark)');
    const isMobile = useMediaQuery('(max-width:600px)');

    const useDark = themeSelected === "dark" || (themeSelected === "system" && systemDark)

    const theme = createTheme({
        palette: {
            mode: useDark ? "dark" : "light",
            background: {
                default: useDark ? "#1E1E1E" : "#F4F4F4",
                paper: useDark ? "#121212" : "#FFFFFF",
            }
        },
        components: {
            MuiCard: {
                defaultProps: {
                    variant: "outlined",
                    sx: {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100%"
                    }
                }
            },
            MuiTextField: {
                defaultProps: {
                    size: "small",
                    margin: "dense"
                }
            },
            MuiSelect: {
                defaultProps: {
                    size: "small"
                }
            },
            MuiButton: {
                defaultProps: {
                    variant: "contained"
                }
            },
            MuiContainer: {
                defaultProps: {
                    sx: {
                        paddingTop: isMobile ? 0 : 2,
                        paddingBottom: 1,
                    }
                }
            },
        }
    });

    return theme;

}