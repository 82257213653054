import { createSlice } from '@reduxjs/toolkit'
import { createJob, deleteJob, editJob, getJob } from './job.api'
import { InterfaceJobData } from './job.interface'

export interface JobState {
    loading: boolean,
    error: any,
    jobList: InterfaceJobData[],
    clientId?: number
}

const initialState: JobState = {
    loading: false,
    error: undefined,
    jobList: [],
    clientId: undefined
}

export const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {
        setClientId: (state, action) => {
            state.clientId = action.payload
        }
    },
    extraReducers(builder) {

        builder.addCase(getJob.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getJob.fulfilled, (state, action) => {
            state.loading = false
            state.jobList = action.payload.data.data
        })
        builder.addCase(getJob.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(createJob.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createJob.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createJob.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(editJob.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editJob.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editJob.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(deleteJob.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteJob.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteJob.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
    },
})

export const { setClientId } = jobSlice.actions

export default jobSlice.reducer