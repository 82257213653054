import { Box, Card, CardContent, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLogDay } from "../data/log.api";
import { RootState } from "../../../store";
import secondsFormat from "../../../shared/secondsFormat";
import { ArrowBackIos, ArrowForwardIos, Refresh } from "@mui/icons-material";
import moment from "moment";
import dataProcessToPie from "../../../shared/dataProcessToPie";
import { PieChart } from "@mui/x-charts";

export default function LogToday() {

    const dateToday = moment().format("YYYY-MM-DD")

    const dispatch = useDispatch<any>()

    const { logTodayList } = useSelector((state: RootState) => state.log)

    const [date, setDate] = useState<string>(dateToday)

    useEffect(() => {
        dispatch(getLogDay(date))
    }, [dispatch, date])

    const taskList = logTodayList.filter((log) => log.task_id !== null)
    const pieData = dataProcessToPie(taskList, date)

    const changeDate = (value: 1 | -1) => {
        const newDate = moment(date)
        newDate.add(value, "day")
        setDate(newDate.format("YYYY-MM-DD"))
    }

    const dateTask = moment(taskList[taskList.length - 1]?.ts_created).format("YYYY-MM-DD")

    return (
        <Card>
            <CardContent>
                <Box display={"flex"} gap={2}>
                    <Box height={200}>
                        <PieChart
                            series={[{
                                data: pieData.map((data, i) => {
                                    return {
                                        id: i,
                                        label: data.client_name,
                                        color: data.client_color,
                                        value: data.value
                                    }
                                }),
                                valueFormatter: (v) => secondsFormat(v.value)
                            }]}
                            legend={{
                                hidden: true
                            }}
                            margin={{
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            }}
                            width={200}
                        />
                    </Box>
                    <Box display={"flex"} flexGrow={1} justifyContent={"space-between"} flexWrap={"wrap"}>
                        <Typography variant="h6">
                            {secondsFormat(taskList.reduce((acc, log) => acc + log.total_second, 0))} total worked {dateTask === dateToday ? "today" : dateTask}
                        </Typography>
                        <Box display={"flex"} gap={1} alignItems={"flex-end"} justifyContent={"flex-end"}>
                            <IconButton onClick={() => changeDate(-1)}>
                                <ArrowBackIos />
                            </IconButton>
                            <IconButton onClick={() => dispatch(getLogDay(date))}>
                                <Refresh />
                            </IconButton>
                            <IconButton onClick={() => changeDate(1)}>
                                <ArrowForwardIos />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>

    )
}