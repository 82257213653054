import { Container, Grid } from "@mui/material";
import { TaskUncomplete } from "../features/task/components/TaskUncomplete";
import LogPresent from "../features/log/components/LogPresent";
import LogToday from "../features/log/components/LogToday";
import RoutineList from "../features/routine/components/RoutineList";
import Queue from "../features/queue/components/Queue";
import TaskRecent from "../features/task/components/TaskRecent";

export default function DashboardPage() {
    return (
        <Container maxWidth={"xl"}>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={8} >
                    <LogPresent />
                </Grid>
                <Grid item xs={12} md={6} lg={4} >
                    <LogToday />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <RoutineList />
                    <Queue />
                    <TaskRecent />
                </Grid>
                <Grid item xs={12} lg={9}>
                    <TaskUncomplete />
                </Grid>
            </Grid>
        </Container>
    )
}