import { InterfaceTaskData } from "../../task/data/task.interface"

export interface InterfaceClientData {
    id: number,
    ts_created: string,
    name: string,
    color: string,
    phone: string,
    email: string,
    tax_percentage: number,
    is_active: boolean
}

export interface InterfaceClientForm {
    id: number,
    name: string,
    color: string,
    phone: string,
    email: string,
    tax_percentage: number,
    is_active: boolean
}

export interface InterfaceClientDataDetail {
    id: number,
    ts_created: string,
    name: string,
    color: string,
    phone: string,
    email: string,
    tax_percentage: number,
    is_active: boolean,
    job_list: {
        id: number,
        name: string,
        color: string,
        client_id: number,
        price: number,
        price_additional: number,
        task_list: InterfaceTaskData[]
    }[]
}

export interface InterfaceClientFinanceChild {
    name: string,
    job_list: {
        name: string,
        task_list: {
            price: number,
            cash_list: {
                value: number
            }[]
        }[]
    }[]
}
export interface InterfaceClientFinance {
    uncomplete: InterfaceClientFinanceChild[],
    done: InterfaceClientFinanceChild[],
    pending: InterfaceClientFinanceChild[]
}

export interface InterfaceClientCreate {
    name: string,
    color: string,
    phone: string,
    email: string,
    tax_percentage: number,
    is_active: boolean
}

export interface InterfaceClientEdit {
    id: number,
    name?: string,
    color?: string,
    phone?: string,
    email?: string,
    tax_percentage?: number,
    is_active?: boolean
}

export const InitialClientDataDetail: InterfaceClientDataDetail = {
    id: 0,
    ts_created: "",
    name: "",
    color: "",
    phone: "",
    email: "",
    tax_percentage: 0,
    is_active: false,
    job_list: []
}

export const InitialClientFinance: InterfaceClientFinance = {
    uncomplete: [],
    done: [],
    pending: []
}

export const InitialClientForm: InterfaceClientForm = {
    id: 0,
    name: "",
    color: "",
    phone: "",
    email: "",
    tax_percentage: 0,
    is_active: false
}