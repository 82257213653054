import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getJob } from "../data/job.api";
import { getClient } from "../../client/data/client.api";
import { InterfaceJobData } from "../data/job.interface";

export default function JobSelect(props: { value: number | undefined, onChange: (value: number | undefined, valueDetail: InterfaceJobData | undefined) => void }) {

    const dispatch = useDispatch<any>()

    const { clientList } = useSelector((state: RootState) => state.client)
    const clientLoading = useSelector((state: RootState) => state.client.loading)
    const { loading, jobList } = useSelector((state: RootState) => state.job)

    const [clientIdSelected, setClientIdSelected] = useState<number | undefined>(undefined)

    useEffect(() => {
        if (!loading && jobList.length > 0 && clientIdSelected === undefined) {
            const jobSelected = jobList.filter(job => job.id === props.value)[0]
            setClientIdSelected(jobSelected?.client_id)
        }
    }, [loading, jobList, props.value, clientIdSelected])

    useEffect(() => {
        dispatch(getClient())
        dispatch(getJob())
    }, [dispatch])

    useEffect(() => {
        if (clientIdSelected) {
            dispatch(getJob(clientIdSelected))
        }
    }, [dispatch, clientIdSelected])

    return (
        <Box>
            {(loading || clientLoading)
                ? (
                    <TextField fullWidth label="Job" />
                )
                : (
                    <Box display={"flex"} gap={1}>
                        <Autocomplete
                            disablePortal
                            fullWidth
                            options={clientList}
                            getOptionLabel={options => options.name}
                            value={clientList.filter(client => client.id === clientIdSelected)[0]}
                            onChange={(_, value) => setClientIdSelected(value?.id || undefined)}
                            renderInput={(params) => <TextField {...params} label="Client" required />}
                        />
                        {clientIdSelected && (
                            <Autocomplete
                                disablePortal
                                fullWidth
                                options={jobList}
                                getOptionLabel={options => options.name}
                                value={jobList.filter(job => job.id === props.value)[0]}
                                onChange={(_, value) => props.onChange(value?.id || undefined, value || undefined)}
                                renderInput={(params) => <TextField {...params} label="Job" required />}
                            />
                        )}
                    </Box>
                )
            }
        </Box>
    )
}