import { Box, Button } from "@mui/material";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import FormText from "../../../shared/form/FormText";
import { useDispatch } from "react-redux";
import alertSuccess from "../../../shared/alert/alertSuccess";
import FormColor from "../../../shared/form/FormColor";
import { createJob, editJob, getJob } from "../data/job.api";
import FormMoney from "../../../shared/form/FormMoney";
import FormCheck from "../../../shared/form/FormCheck";
import { InitialJobForm, InterfaceJobForm } from "../data/job.interface";
import ClientSelect from "../../client/components/ClientSelect";

export default function JobForm(props: { mode: "ADD" | "EDIT", data?: InterfaceJobForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const [values, setValues] = useState<InterfaceJobForm>(InitialJobForm)

    useEffect(() => {
        if (props.data !== undefined) {
            setValues(props.data)
        }
    }, [props.data])

    const handleChangeValues = (e: ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target
        let value = name === "is_active" ? (e.target.value === "1" ? true : false) : e.target.value
        setValues(last => ({
            ...last,
            [name]: value
        }))
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createJob(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editJob(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            setValues(InitialJobForm)
            dispatch(getJob())
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <ClientSelect value={values.client_id} onChange={value => setValues(last => ({ ...last, client_id: value || 0 }))} />
                <FormText label="Name" name="name" value={values.name} required onChange={handleChangeValues} />
                <FormColor label="Color" name="color" value={values.color} required onChange={handleChangeValues} />
                <FormMoney label="Price Additional" name="price_additional" value={values.price_additional} required onChange={handleChangeValues} />
                <FormCheck label="Active" name="is_active" value={values.is_active} onChange={handleChangeValues} />
                <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button type="submit">Simpan</Button>
                </Box>
            </form>
        </Box>
    )
}