export interface InterfaceJobData {
    id: number,
    ts_created: string,
    client_id: number,
    name: string,
    color: string,
    price_additional: number,
    is_active: boolean
}

export interface InterfaceJobCreate {
    client_id: number,
    name: string,
    color: string,
    price_additional: number
}

export interface InterfaceJobForm {
    id: number,
    client_id: number,
    name: string,
    color: string,
    price_additional: number,
    is_active: boolean
}

export interface InterfaceJobEdit {
    id: number,
    client_id?: number,
    name?: string,
    color?: string,
    price_additional?: number,
    is_active?: boolean
}

export const InitialJobForm: InterfaceJobForm = {
    id: 0,
    client_id: 0,
    name: "",
    color: "",
    price_additional: 0,
    is_active: true
}