import { Box, Card, CardContent, CardHeader, Grid, IconButton, List, ListItem, Popover, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { KeyboardArrowDown } from "@mui/icons-material";
import { RootState } from "../../../store";
import { getClientFinance } from "../data/client.api";
import moneyParser from "../../../shared/moneyParser";
import { InterfaceClientFinanceChild } from "../data/client.interface";

const ClientFinanceChild = (props: { name: string, clientList: InterfaceClientFinanceChild[] }) => {

    const [popupDetail, setPopupDetail] = useState<null | HTMLElement>(null)

    const clientTotal = (client: InterfaceClientFinanceChild) => {
        return client.job_list.reduce((totalJob, job) => totalJob + job.task_list.reduce((totalTask, task) => totalTask + (task.price - task.cash_list.reduce((totalCash, cash) => totalCash + cash.value, 0)), 0), 0)
    }

    const clientTotalEarly = (client: InterfaceClientFinanceChild) => {
        return client.job_list.reduce((totalJob, job) => totalJob + job.task_list.flatMap(task => task.cash_list).reduce((totalCash, cash) => totalCash + cash.value, 0), 0)
    }

    const clientListTotalEarly = props.clientList.reduce((totalClient, client) => totalClient + clientTotalEarly(client), 0)

    return (
        <Card>
            <Popover
                open={Boolean(popupDetail)}
                anchorEl={popupDetail}
                onClose={() => setPopupDetail(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List>
                    {props.clientList.map((client) => {
                        const totalEarly = clientTotalEarly(client)
                        return (
                            <ListItem key={client.name}>
                                <Box display={"flex"} flexDirection={"column"} flexGrow={1}>
                                    <Box display={"flex"}>
                                        <Typography variant="body2">{client.name}</Typography>
                                        {totalEarly > 0 && (<Typography variant="body2" marginLeft={1} color={"green"}>+{moneyParser(totalEarly)}</Typography>)}
                                    </Box>
                                    <Typography variant="h6" align="right">{moneyParser(clientTotal(client))}</Typography>
                                </Box>
                            </ListItem>
                        )
                    })}
                </List>
            </Popover>
            <CardHeader
                title={props.name}
            />
            <CardContent>
                <Box display={"flex"} alignItems={"flex-end"} justifyContent={"flex-end"}>
                    <Typography variant="h4">
                        {moneyParser(props.clientList.reduce((totalClient, client) => totalClient + clientTotal(client), 0))}
                    </Typography>
                    {clientListTotalEarly > 0 && (<Typography variant="body1" marginLeft={1} color={"green"}>+{moneyParser(clientListTotalEarly)}</Typography>)}
                    <IconButton onClick={e => setPopupDetail(e.currentTarget)}>
                        <KeyboardArrowDown />
                    </IconButton>
                </Box>
            </CardContent>
        </Card>
    )
}

export default function ClientFinanceCount() {

    const dispatch = useDispatch<any>()

    const { clientFinance } = useSelector((state: RootState) => state.client)

    useEffect(() => {
        dispatch(getClientFinance())
    }, [dispatch])

    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={4}>
                    <ClientFinanceChild name="Uncomplete" clientList={clientFinance.uncomplete} />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <ClientFinanceChild name="Done" clientList={clientFinance.done} />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <ClientFinanceChild name="Pending" clientList={clientFinance.pending} />
                </Grid>
            </Grid>
        </Box>
    )
}