import { createSlice } from '@reduxjs/toolkit'
import { createRoutine, deleteRoutine, editRoutine, getRoutine } from './routine.api'
import { InterfaceRoutineData } from './routine.interface'

export interface RoutineState {
    loading: boolean,
    error: any,
    routineList: InterfaceRoutineData[]
}

const initialState: RoutineState = {
    loading: false,
    error: undefined,
    routineList: []
}

export const routineSlice = createSlice({
    name: 'routine',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getRoutine.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getRoutine.fulfilled, (state, action) => {
            state.loading = false
            state.routineList = action.payload.data.data
        })
        builder.addCase(getRoutine.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(createRoutine.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createRoutine.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createRoutine.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(editRoutine.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editRoutine.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editRoutine.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(deleteRoutine.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteRoutine.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteRoutine.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
    },
})

export default routineSlice.reducer