import { Box, Button, Card, CardContent, Chip, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createLog, getLogLatest } from "../data/log.api";
import { useEffect, useState } from "react";
import { RootState } from "../../../store";
import Color from "../../../shared/Color";
import moment from "moment";
import secondsFormat from "../../../shared/secondsFormat";
import Swal from "sweetalert2";
import { editTask, getTaskUncomplete } from "../../task/data/task.api";
import alertSuccess from "../../../shared/alert/alertSuccess";

export default function LogPresent() {

    const dispatch = useDispatch<any>()

    const { logLatest } = useSelector((state: RootState) => state.log)

    const [time, setTime] = useState("--:--:--")
    const [timeAll, setTimeAll] = useState("--:--:--")

    useEffect(() => {
        dispatch(getLogLatest())
    }, [dispatch])

    useEffect(() => {
        const updateTime = () => {
            const latestDuration = moment.duration(moment().diff(moment(logLatest.ts_created)))
            const totalSecondAll = moment.duration(logLatest.total_second_all, "seconds")
            const totalDuration = latestDuration.clone().add(totalSecondAll)
            setTime((latestDuration.get('hours') > 0 ? `${latestDuration.get('hours')}:` : "") + latestDuration.get('minutes').toString().padStart(2, '0') + ":" + latestDuration.get('seconds').toString().padStart(2, '0'))
            setTimeAll(secondsFormat(totalDuration.asSeconds()))
        }
        updateTime()
        const interval = setInterval(() => {
            updateTime()
        }, 1000)
        return () => clearInterval(interval)
    }, [logLatest])

    const handleBreak = async () => {
        await dispatch(createLog({ task_id: undefined })).unwrap()
        await dispatch(getLogLatest()).unwrap()
    }

    const handleDone = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, done it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    if (logLatest.task_id !== undefined) {
                        await dispatch(editTask({ id: logLatest.task_id, is_done: true })).unwrap()
                        await alertSuccess("Task is done")
                        await dispatch(getTaskUncomplete())
                        await dispatch(getLogLatest())
                    }
                } catch (error) { }
            }
        })
    }

    return (
        <Card>
            <CardContent>
                <Box display={"flex"} justifyContent={"space-between"} flexWrap={"wrap-reverse"}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h4">{logLatest.task?.name || logLatest.routine?.name || "Break"}</Typography>
                                {logLatest.task && (
                                    <Typography variant="body2">{logLatest.task.description}</Typography>
                                )}
                            </Grid>
                            {logLatest.task && (
                                <Grid item xs={12} display={"flex"} gap={1}>
                                    <Chip label={<Box display={"flex"} alignItems={"center"} gap={1}><Color color={logLatest.task.job.client.color} />{logLatest.task.job.client.name}</Box>} />
                                    <Chip label={<Box display={"flex"} alignItems={"center"} gap={1}><Color color={logLatest.task.job.color} />{logLatest.task.job.name}</Box>} />
                                </Grid>
                            )}
                            {(logLatest.task || logLatest.routine) && (
                                <Grid item xs={6} display={"flex"} gap={1}>
                                    <Button onClick={handleBreak}>Break</Button>
                                    {logLatest.task?.is_done === false && <Button onClick={handleDone} variant="outlined">Done</Button>}
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                    <Box display={"flex"} flexGrow={1} alignItems={"center"} justifyContent={"center"}>
                        <Box>
                            <Typography variant="h1">{time}</Typography>
                            {(logLatest.task_id || logLatest.routine) && (
                                <Typography variant="h6" align="right">{timeAll}</Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}