import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";
import { RootState } from "../../../store";
import { InterfaceLogCreate, InterfaceLogEdit } from "./log.interface";

export const getLogLatest = createAsyncThunk('getLogLatest', async (_, thunkAPI) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/log/latest',
        })
        return response
    }
    catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const getLogDay = createAsyncThunk('getLogDay', async (date: string, thunkAPI) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/log/date',
            params: {
                date_start: date,
                date_end: date
            }
        })
        return response
    }
    catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const getLogDate = createAsyncThunk('getLogDate', async (params: { date_start: string, date_end: string }, thunkAPI) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/log/date',
            params: params
        })
        return response
    }
    catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const createLog = createAsyncThunk('createLog', async (data: InterfaceLogCreate, thunkAPI) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/log',
            data: data
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const updateLog = createAsyncThunk('updateLog', async (data: InterfaceLogEdit, thunkAPI) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/log',
            data: data
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})