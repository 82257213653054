import { Box, Button, Card, CardContent, TextField } from "@mui/material";
import { FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../data/auth.api";

export default function Login() {

    const dispatch = useDispatch<any>()

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            await dispatch(login({ username: username, password: password })).unwrap()
            window.location.replace("/")
        } catch (error) { }
    }

    return (
        <Box height={"100vh"} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
            <Card sx={{ height: "auto" }}>
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <TextField required fullWidth onChange={(e) => setUsername(e.target.value)} type="text" label="Nama Pengguna" />
                        <TextField required fullWidth onChange={(e) => setPassword(e.target.value)} type="password" label="Kata Sandi" />
                        <Box display={"flex"} marginTop={1} justifyContent={"flex-end"}>
                            <Button type="submit">Masuk</Button>
                        </Box>
                    </form>
                </CardContent>
            </Card>
        </Box>
    )
}