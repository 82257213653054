import { createSlice } from '@reduxjs/toolkit'
import { createTask, deleteTask, editTask, getTask, getTaskDone, getTaskRecent, getTaskUncomplete } from './task.api'
import { InterfaceTaskData, InterfaceTaskDataTree, InterfaceTaskRecent } from './task.interface'

export interface TaskState {
    loading: boolean,
    error: any,
    taskList: InterfaceTaskData[],
    taskUncomplete: InterfaceTaskDataTree[],
    taskDone: InterfaceTaskDataTree[],
    taskRecentList: InterfaceTaskRecent[]
}

const initialState: TaskState = {
    loading: false,
    error: undefined,
    taskList: [],
    taskUncomplete: [],
    taskDone: [],
    taskRecentList: []
}

export const taskSlice = createSlice({
    name: 'task',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getTask.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getTask.fulfilled, (state, action) => {
            state.loading = false
            state.taskList = action.payload.data.data
        })
        builder.addCase(getTask.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(getTaskUncomplete.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getTaskUncomplete.fulfilled, (state, action) => {
            state.loading = false
            state.taskUncomplete = action.payload.data.data
        })
        builder.addCase(getTaskUncomplete.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(getTaskDone.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getTaskDone.fulfilled, (state, action) => {
            state.loading = false
            state.taskDone = action.payload.data.data
        })
        builder.addCase(getTaskDone.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(getTaskRecent.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getTaskRecent.fulfilled, (state, action) => {
            state.loading = false
            state.taskRecentList = action.payload.data.data
        })
        builder.addCase(getTaskRecent.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(createTask.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createTask.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createTask.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(editTask.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editTask.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editTask.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(deleteTask.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteTask.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteTask.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
    },
})

export default taskSlice.reducer