import { createSlice } from '@reduxjs/toolkit'
import { TypeTheme } from './containers.interface'

export interface ContainersState {
    theme: TypeTheme,
    showMoney: boolean,
}

const initialState: ContainersState = {
    theme: "system",
    showMoney: false,
}

export const containersSlice = createSlice({
    name: 'containers',
    initialState,
    reducers: {
        setTheme: (state, actions) => {
            state.theme = actions.payload
        },
        toggleMoney: (state) => {
            state.showMoney = !state.showMoney
        }
    }
})

export const { setTheme, toggleMoney } = containersSlice.actions

export default containersSlice.reducer