import { InterfaceRoutineData } from "../../routine/data/routine.interface"

export interface InterfaceLogDataDetail {
    id: number,
    ts_created: string,
    total_second: number,
    total_second_all: number,
    task_id?: number,
    task?: {
        name: string,
        description?: string,
        is_done: boolean,
        job: {
            name: string,
            color: string,
            client: {
                name: string,
                color: string
            }
        }
    },
    routine?: InterfaceRoutineData
}

export interface InterfaceLogCreate {
    task_id?: number,
    routine_id?: number
}

export interface InterfaceLogEdit {
    id?: number,
    ts_created?: string
}

export interface InterfaceLogForm {
    id: number,
    task_id: number
}

export const InitialLogDataDetail: InterfaceLogDataDetail = {
    id: 0,
    ts_created: '',
    total_second: 0,
    total_second_all: 0,
    task_id: 0,
    task: {
        name: '',
        is_done: false,
        job: {
            name: '',
            color: '',
            client: {
                name: '',
                color: ''
            }
        }
    }
}

export const InitialLogForm: InterfaceLogForm = {
    id: 0,
    task_id: 0
}