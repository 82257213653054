import { createSlice } from '@reduxjs/toolkit'
import { createClient, deleteClient, editClient, getClient, getClientDetail, getClientFinance } from './client.api'
import { InitialClientDataDetail, InitialClientFinance, InterfaceClientData, InterfaceClientDataDetail, InterfaceClientFinance } from './client.interface'

export interface ClientState {
    loading: boolean,
    error: any,
    clientList: InterfaceClientData[],
    clientDetail: InterfaceClientDataDetail,
    clientFinance: InterfaceClientFinance
}

const initialState: ClientState = {
    loading: false,
    error: undefined,
    clientList: [],
    clientDetail: InitialClientDataDetail,
    clientFinance: InitialClientFinance
}

export const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getClient.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getClient.fulfilled, (state, action) => {
            state.loading = false
            state.clientList = action.payload.data.data
        })
        builder.addCase(getClient.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(getClientDetail.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getClientDetail.fulfilled, (state, action) => {
            state.loading = false
            state.clientDetail = action.payload.data.data
        })
        builder.addCase(getClientDetail.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(getClientFinance.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getClientFinance.fulfilled, (state, action) => {
            state.loading = false
            state.clientFinance = action.payload.data.data
        })
        builder.addCase(getClientFinance.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(createClient.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createClient.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createClient.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(editClient.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editClient.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editClient.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(deleteClient.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteClient.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteClient.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
    },
})

export default clientSlice.reducer