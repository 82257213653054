import { ArrowBackIos, ArrowForwardIos, Info } from "@mui/icons-material";
import { Box, Button, Card, CardContent, IconButton, Table, TableBody, TableCell, TableRow, TextField, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { getLogDate, updateLog } from "../data/log.api";
import { InterfaceLogDataDetail } from "../data/log.interface";
import secondsFormat from "../../../shared/secondsFormat";
import { setStatisticDateStart } from "../data/log.reducer";

export default function LogStatisticHeader() {

    const dispatch = useDispatch<AppDispatch>()

    const { logList } = useSelector((state: RootState) => state.log)
    const dateStart = useSelector((state: RootState) => state.log.statisticDateStart)
    const dateToday = useSelector((state: RootState) => state.log.statisticDateEnd)

    const [modalOpen, setModalOpen] = useState(false)
    const [changedLog, setChangedLog] = useState<InterfaceLogDataDetail | null>(null)

    const changeDate = (value: 1 | -1) => {
        const newDate = moment(dateStart)
        newDate.add(value, "day")
        dispatch(setStatisticDateStart(newDate.format("YYYY-MM-DD")))
    }

    useEffect(() => {
        dispatch(getLogDate({ date_start: dateStart, date_end: dateToday }))
    }, [dispatch, dateToday, dateStart])

    const handleSave = async () => {
        if (changedLog !== null) {
            await dispatch(updateLog(changedLog))
            setChangedLog(null)
            dispatch(getLogDate({ date_start: dateStart, date_end: dateToday }))
        }
    }

    return (
        <Card>
            <CardContent>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant="h6">{dateStart}{dateToday !== dateStart && ` - ${dateToday}`}</Typography>
                    <Box display={"flex"} gap={1}>
                        <IconButton onClick={() => setModalOpen(!modalOpen)}>
                            <Info />
                        </IconButton>
                        <IconButton onClick={() => changeDate(-1)}>
                            <ArrowBackIos />
                        </IconButton>
                        <IconButton onClick={() => changeDate(1)} disabled={dateStart === dateToday}>
                            <ArrowForwardIos />
                        </IconButton>
                    </Box>
                </Box>
                <Card sx={{ marginBottom: "1px", marginTop: 2, display: modalOpen ? "inherit" : "none", overflow: "auto" }}>
                    <CardContent>
                        <Table>
                            <TableBody>
                                {logList.map((log) => {
                                    const isThisDataChanged = changedLog !== null && changedLog.id === log.id
                                    return (
                                        <TableRow key={log.id}>
                                            <TableCell>{log.id}</TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="datetime-local"
                                                    value={moment(isThisDataChanged ? changedLog.ts_created : log.ts_created).format("YYYY-MM-DD HH:mm:ss")}
                                                    onChange={(e) => setChangedLog({
                                                        ...log,
                                                        ts_created: e.target.value
                                                    })}
                                                />
                                            </TableCell>
                                            <TableCell>{secondsFormat(log.total_second)}</TableCell>
                                            <TableCell>{log.task?.job.client.name}</TableCell>
                                            <TableCell>{log.task?.job.name}</TableCell>
                                            <TableCell colSpan={isThisDataChanged ? 1 : 2}>{log.task?.name || log.routine?.name}</TableCell>
                                            {isThisDataChanged && (
                                                <TableCell><Button onClick={handleSave}>Save</Button></TableCell>
                                            )}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </CardContent>
        </Card>
    )
}