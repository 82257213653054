import { Container, Typography } from "@mui/material";
import { TaskDone } from "../../features/task/components/TaskDone";

export default function ReadyToBillPage() {
    return (
        <Container>
            <Typography variant="h3">Ready to Bill</Typography>
            <br />
            <TaskDone />
        </Container>
    )
}