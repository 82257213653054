import { createSlice } from "@reduxjs/toolkit"
import { InterfaceQueueData } from "./queue.interface"
import { createQueue, deleteQueue, editQueue, getQueue } from "./queue.api"

export interface QueueState {
    loading: boolean,
    error: any,
    queueList: InterfaceQueueData[]
}

const initialState: QueueState = {
    loading: false,
    error: undefined,
    queueList: []
}

export const queueSlice = createSlice({
    name: 'queue',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getQueue.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getQueue.fulfilled, (state, action) => {
            state.loading = false
            state.queueList = action.payload.data.data
        })
        builder.addCase(getQueue.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(createQueue.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createQueue.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createQueue.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(editQueue.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editQueue.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editQueue.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

        builder.addCase(deleteQueue.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteQueue.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteQueue.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })

    }
})

export default queueSlice.reducer