import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { InterfaceLogDataDetail } from "../data/log.interface";
import dataProcessToDaily from "../../../shared/dataProcessToDaily";
import moment from "moment";
import dataProcessToPie from "../../../shared/dataProcessToPie";
import secondsFormat from "../../../shared/secondsFormat";
import { BarChart, PieChart } from "@mui/x-charts";

export default function LogWeek() {

    const { logList, statisticDateStart, statisticDateEnd, } = useSelector((state: RootState) => state.log)

    const logListDaily: InterfaceLogDataDetail[][] = dataProcessToDaily(statisticDateStart, statisticDateEnd, logList)
    const pieData = dataProcessToPie(logList)

    const totalSeconds = logList.filter(log => log.task_id).reduce((last, log) => last + (log.total_second || 0), 0)
    const totalHours = totalSeconds / 3600

    return (
        <Card>
            <CardContent>
                <Typography variant='h5' sx={{ marginTop: 1 }} marginBottom={1}>
                    {totalHours.toFixed(2)} total hours for last {moment(statisticDateEnd).diff(moment(statisticDateStart), 'day') + 1} days
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={2} height={216} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <PieChart
                            series={[{
                                data: pieData.map((data, i) => {
                                    return {
                                        id: i,
                                        label: data.client_name,
                                        color: data.client_color,
                                        value: data.value
                                    }
                                }),
                                valueFormatter: (v) => {
                                    const totalSeconds = pieData.reduce((total, log) => total + log.value, 0)
                                    return `${secondsFormat(v.value)} (${(v.value / totalSeconds * 100).toFixed(0)}%)`
                                }
                            }]}
                            legend={{
                                hidden: true
                            }}
                            margin={{
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            }}
                            height={200}
                        />
                    </Grid>
                    <Grid item xs={12} lg={10}>
                        <BarChart
                            height={250}
                            series={
                                pieData.map((data, i) => ({
                                    id: i,
                                    label: data.client_name,
                                    color: data.client_color,
                                    stack: "total",
                                    valueFormatter: (v) => secondsFormat(v || 0),
                                    data: logListDaily
                                        .map(logByDate => logByDate
                                            .filter(log => log.task?.job.client.name === data.client_name)
                                            .reduce((last, log) => last + (log.total_second || 0), 0)
                                        )
                                }))
                            }
                            xAxis={[{
                                data: logListDaily.map((_, i) => moment(statisticDateStart).add(i, "day").format("YYYY-MM-DD")),
                                scaleType: 'band'
                            }]}
                            yAxis={[{
                                valueFormatter: (v) => secondsFormat(v)
                            }]}
                            legend={{
                                hidden: true
                            }}
                            margin={{
                                left: 75
                            }}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}