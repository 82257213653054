import { Checkbox, FormControlLabel } from "@mui/material";
import { ChangeEvent, ChangeEventHandler } from "react";

export default function FormCheck(props: {
    label: string,
    name: string,
    value: boolean,
    disabled?: boolean,
    onChange: ChangeEventHandler<HTMLInputElement>
}) {

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.onChange && props.onChange({
            ...e,
            target: {
                ...e.target,
                name: props.name || "",
                value: e.target.checked ? "1" : "0"
            }
        })
    }

    return (
        <FormControlLabel
            control={
                <Checkbox
                    onChange={handleChange}
                    name={props.name}
                    checked={props.value}
                    disabled={props.disabled}
                />
            }
            label={props.label}
        />
    );
}