import { Container, Typography } from "@mui/material";
import BillTable from "../../features/bill/components/BillTable";

export default function BillPage() {
    return (
        <Container>
            <Typography variant="h3">Bill</Typography>
            <br />
            <BillTable />
        </Container>
    )
}