import { createSlice } from '@reduxjs/toolkit'
import { login } from './auth.api'
import { InitialLoginResponse, InterfaceLoginResponse } from './auth.interface'

export interface AuthState {
    loading: boolean,
    error: any,
    loginData: InterfaceLoginResponse
}

const initialState: AuthState = {
    loading: false,
    error: undefined,
    loginData: InitialLoginResponse
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetLoginData: (state) => {
            state.loginData = InitialLoginResponse
        },
        resetError: (state) => {
            state.error = undefined
        }
    },
    extraReducers(builder) {

        builder.addCase(login.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(login.fulfilled, (state, action) => {
            state.loading = false
            state.loginData = action.payload.data.data
        })
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
    },
})

export const { resetLoginData, resetError } = authSlice.actions

export default authSlice.reducer