import { Box, Button } from "@mui/material";
import CustomTable from "../../../shared/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect, useState } from "react";
import { deleteBill, getBill, payBill } from "../data/bill.api";
import { InterfaceBillData } from "../data/bill.interface";
import moneyParser from "../../../shared/moneyParser";
import moment from "moment";
import CustomModal from "../../../shared/CustomModal";
import BillForm from "./BillForm";
import alertDelete from "../../../shared/alert/alertDelete";
import { Check, Delete } from "@mui/icons-material";
import Swal from "sweetalert2";

export default function BillTable() {

    const dispatch = useDispatch<any>()

    const [idSelected, setIdSelected] = useState<number | null>(null)

    const { billList } = useSelector((state: RootState) => state.bill)

    useEffect(() => {
        dispatch(getBill());
    }, [dispatch])

    const handleDelete = async (id: number) => {
        alertDelete()
            .then(result => {
                if (result) {
                    dispatch(deleteBill(id)).unwrap()
                        .then(() => {
                            dispatch(getBill())
                        })
                }
            })
    }

    const handlePay = async (id: number) => {
        Swal.fire({
            icon: "question",
            title: "Already Paid?",
            showCancelButton: true
        })
            .then(result => {
                if (result.isConfirmed) {
                    dispatch(payBill({ id, date_paid: moment().format("YYYY-MM-DD") })).unwrap()
                        .then(() => {
                            dispatch(getBill())
                        })
                }
            })
    }


    return (
        <Box>
            <CustomModal
                title={"Bill"}
                open={idSelected !== null}
                onClose={() => setIdSelected(null)}
                component={idSelected !== null && <BillForm billId={idSelected} />}
            />
            <CustomTable
                columns={[
                    {
                        name: "Client",
                        key: "client_id",
                        render: (row: InterfaceBillData) => row.client.name
                    },
                    {
                        name: "Total",
                        key: "task_list",
                        render: (row: InterfaceBillData) => moneyParser(row.task_list.reduce((total, task) => total + task.price, 0))
                    },
                    {
                        name: "Date Paid",
                        key: "date_paid",
                        render: (row: InterfaceBillData) => row.date_paid ? moment(row.date_paid).format("YYYY-MM-DD") : "Not Paid"
                    }
                ]}
                dataList={billList}
                actionList={(data: InterfaceBillData) => (
                    <Box display={"flex"} justifyContent={"center"} gap={1}>
                        <Button onClick={() => setIdSelected(data.id)}>Sheet</Button>
                        {!Boolean(data.date_paid) && (<Button startIcon={<Check />} color="success" onClick={() => handlePay(data.id)}>Paid</Button>)}
                        <Button startIcon={<Delete />} color="error" onClick={() => handleDelete(data.id)}>Hapus</Button>
                    </Box>
                )}
            />
        </Box>
    )
}